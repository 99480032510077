import { withOktaAuth } from '@okta/okta-react';
import { listingTypes } from 'components/documentlibrary/documentLibraryEnum';
import ApplicationContext from 'context/ApplicationContext';
import { isPersonaIsOKTA } from 'data/config/datasetup';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useGetDocumentListing from 'hooks/useGetDocumentListing';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import LibraryDesktop from '../../components/documentlibrary/LibraryDesktop';
import LibraryGridContainer from '../../components/documentlibrary/LibraryGridContainer';
import useResponsive from '../../hooks/useResponsive';
import DrcContainer from '../../layouts/dashboard/DrcContainer';

const DocumentLibrary = (props) => {
    const { oktaAuth } = props;
    const { getTextTranslated } = useLang();
    useCheckMasterData();
    const navigate = useNavigate();
    const loc = useLocation();
    const { query, fileName } = useParams();
    const path = decodeURI(loc.pathname.split('/').pop());
    const documentId = path !== listingTypes.LIBRARY && path ? path : listingTypes.SITES;
    const [searchInput, setSearchInput] = useState('');
    const [loading, setLoading] = useState(false);
    const { userPersona } = useContext(ApplicationContext);
    const isOktaUser = isPersonaIsOKTA(userPersona);
    const { getLatestItems, downloadDocument, search, fetchListing, documentList, executionTime, skipToken } = useGetDocumentListing(oktaAuth, '');
    const isDesktop = useResponsive('up', 'md');
    const perPageData = 50;

    useEffect(() => {
        getData();
    }, [fileName, documentId]);

    const getData = async () => {
        setLoading(true);
        if (!query) {
            if (documentId !== listingTypes.LATEST) {
                await fetchListing(fileName !== listingTypes.LIBRARY && fileName ? fileName : listingTypes.SITES, perPageData, '', false);
            } else {
                await getLatestItems();
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        getSearchData();
    }, [query]);

    const getSearchData = async () => {
        if (query) {
            setLoading(true);
            await search(query);
            setLoading(false);
        }
    };

    const getDocumentList = (rowData) => {
        navigate(routes[routesName.DOCUMENTS_FILE].getRoute(rowData.id));
    };

    const onSearch = () => {
        if (searchInput) {
            navigate(routes[routesName.DOCUMENT_SEARCH].getRoute(searchInput));
        }
    };

    const downloadSharepointDocument = (itemId, listId, fileOpenURL) => {
        if (isOktaUser) {
            window.open(fileOpenURL, '_blank');
        } else {
            downloadDocument(itemId, listId);
        }
    };

    const handleLoadMore = async () => {
        setLoading(true);
        await fetchListing(fileName, perPageData, skipToken, true);
        setLoading(false);
    };

    return (
        <DrcContainer heading="Document Library">
            <LibraryDesktop isDesktop={isDesktop} setSearchInput={setSearchInput} searchInput={searchInput} onEnterPress={onSearch} />
            <LibraryGridContainer
                disableLoadMore={loading}
                type={documentId}
                totalRecords={documentList?.length}
                loading={loading}
                title={getTextTranslated('Document Library', NAMESPACE.COMMON)}
                documentList={documentList}
                skipToken={skipToken}
                handleLoadMore={handleLoadMore}
                pageCount={1}
                page={1}
                handlePageChange
                executionTime={executionTime}
                getListing={getDocumentList}
                downloadDocument={downloadSharepointDocument}
            />
        </DrcContainer>
    );
};

export default withOktaAuth(DocumentLibrary);
