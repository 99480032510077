import { Call } from '@driscollsinc/driscolls-display-rules';
import { TFeatureDetailsType } from '@driscollsinc/users-management-module-ui';
import useGetApiTokens from 'hooks/useGetApiTokens';
import { useState } from 'react';
import { GET_ALL_AVAILABLE_FEATURES, GET_SELECTED_FEATURE_DETAILS, POST_FEATURES } from 'services/api/v2/FeatureMngmntAPI';
import { FeatureData, FeaturePayloadData, FeatureResponseData } from 'types/v2/featuremanagement';
const useFeatureDetails = (oktaAuth: any) => {
    const [loading, setLoading] = useState(true);
    const { getTokenAndId } = useGetApiTokens();
    const [error, setError] = useState(null);
    const [featureData, setFeatureData] = useState<Array<FeatureData> | []>([]);
    const [featureTotalItems, setFeatureTotalItems] = useState(1);
    const [featureDetails, setFeatureDetails] = useState<TFeatureDetailsType | object>({});
    const getAvailableFeatures = async (pageNumber?: string) => {
        setLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: GET_ALL_AVAILABLE_FEATURES(pageNumber ? pageNumber : '1'),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (response.display && response.display.length > 0) {
                setFeatureTotalItems(response.display.length);
                const mappedData: FeatureData[] = response.display.map((item: FeatureResponseData) => ({
                    id: item.FeatureId,
                    name: item.FeatureName,
                    description: item.Description,
                    displayName: item.FeatureName.charAt(0).toUpperCase() + item.FeatureName.slice(1),
                    applicationName: item.ApplicationName
                }));
                setFeatureData(mappedData);
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const createFeature = async (payload: FeaturePayloadData) => {
        setLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: POST_FEATURES(),
                    method: 'POST',
                    options: {},
                    data: payload
                },
                token
            );
            if (response.display) {
                return response.display;
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchFeatureDetails = async (id: string) => {
        setLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: GET_SELECTED_FEATURE_DETAILS(id),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (response.display) {
                const featureData = {} as TFeatureDetailsType;
                Object.entries(response.display).forEach(([key, value]: [string, FeatureResponseData]) => {
                    featureData.featureName = value.FeatureName;
                    featureData.displayName = value.FeatureName;
                    featureData.description = value.Description;
                    featureData.id = value.FeatureId;
                });
                return featureData;
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };
    return { error, loading, featureData, getAvailableFeatures, createFeature, featureTotalItems, fetchFeatureDetails, featureDetails };
};

export default useFeatureDetails;
