import React, { useContext, useEffect } from 'react';
import ApplicationContext from '../../context/ApplicationContext';

import { syncTranslationWeather, WeatherEntryPoint } from '@driscollsinc/weather-module-ui';
import { Container } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import useLang from 'hooks/useLang';
import useWeatherService from 'hooks/useWeatherService';
import DrcContainer from 'layouts/dashboard/DrcContainer';

const Weather = () => {
    const { oktaAuth } = useOktaAuth();
    const { userProfile } = useContext(ApplicationContext);
    const { generateWeatherReport, loading } = useWeatherService(oktaAuth);
    const { getCurrentLanguageSelected } = useLang();
    const lang = getCurrentLanguageSelected();

    useEffect(() => {
        syncTranslationWeather(lang);
    }, [lang]);

    return (
        <DrcContainer heading={'Weather'}>
            <Container>
                <WeatherEntryPoint userEmail={userProfile.profile.email} generateWeatherReport={generateWeatherReport} loading={loading} />
            </Container>
        </DrcContainer>
    );
};

export default Weather;
