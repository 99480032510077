import { FeatureManageEntryPoint } from '@driscollsinc/users-management-module-ui';
import { useOktaAuth } from '@okta/okta-react';
import { personaFeatures } from 'enums/v2/personaFeatures';
import useFeatureDetails from 'hooks/v2/useFeatureDetails';
import React, { useEffect, useState } from 'react';
const FeatureManagement = () => {
    const { oktaAuth } = useOktaAuth();

    const { featureData, getAvailableFeatures, featureTotalItems, loading } = useFeatureDetails(oktaAuth);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        getAvailableFeatures(currentPage.toString());
    }, [currentPage]);

    const handleChangePageNumber = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <FeatureManageEntryPoint
            loading={loading}
            data={featureData.slice(currentPage * 10 - 10, currentPage * 10)}
            onPageChange={handleChangePageNumber}
            totalItems={featureTotalItems}
            itemsPerPage={personaFeatures.ItemsPerPage}
            selectedPage={currentPage}
        />
    );
};

export default FeatureManagement;
