import { GAEntryPoint, syncTranslationGa } from '@driscollsinc/ga-module-ui';
import { Container } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import { IAnalyticsDataTable, createDimensionsTableData, createMetricsTableData } from 'components/analytics/analyticsDataTransformation';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import DrcContainer from 'layouts/dashboard/DrcContainer';
import React, { useEffect } from 'react';
import useLang from '../../hooks/useLang';
import style from './Analytics.module.scss';

const Analytics = (props) => {
    const { fetchReport, loading, data, error } = useGoogleAnalytics(props.oktaAuth);
    const { getCurrentLanguageSelected } = useLang();
    const dataTable = createDimensionsTableData(data);
    const metricTable = createMetricsTableData(data);
    const lang = getCurrentLanguageSelected();

    useEffect(() => {
        syncTranslationGa(lang);
    }, [lang]);

    const mergedTable: IAnalyticsDataTable = {
        columns: [...dataTable.columns, ...metricTable.columns],
        rows: dataTable.rows.map((d, i) => ({ values: [...d.values, ...metricTable.rows[i].values] }))
    };
    console.log(mergedTable);

    return (
        <DrcContainer heading="Analytics">
            <Container className={style.GaConatiner}>
                <GAEntryPoint
                    error={error}
                    loading={loading}
                    applyHandler={(dateFilter, dimensions, metrics) => {
                        fetchReport(dateFilter, dimensions, metrics);
                    }}
                    dataTable={mergedTable}
                />
            </Container>
        </DrcContainer>
    );
};

export default withOktaAuth(Analytics);
