import { DriscollsRichText } from '@driscollsinc/style-guide-web2.0';
import { Card, Container, Divider, Grid, Typography } from '@mui/material';
import { withOktaAuth } from '@okta/okta-react';
import ViewAttachments from 'components/announcement/ViewAttachments';
import DrcDialog from 'components/shared/drc/DrcDialog';
import DrcVideoPlayer from 'components/shared/drc/DrcVideoPlayer';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useGetAnnouncements from 'hooks/useGetAnnouncements';
import useGetAttachedFiles from 'hooks/useGetAttachedFiles';
import useReadAnnouncement from 'hooks/useReadAnnouncement';
import { NAMESPACE } from 'i18n';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import { showAttachmentFeature } from 'utils/featureFlagsUtils';
import { isJsonString } from 'utils/helper';
import { TypographyTranslated } from '../../components/shared/Typography/TypographyTranslated';
import ApplicationContext from '../../context/ApplicationContext';
import useGetLocale from '../../hooks/useGetLocale';
import useLang from '../../hooks/useLang';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import TranslatedDrcButton from '../../ui/translatedButton/TranslatedDrcButton';
import { nonConventionalDateFormatter } from '../../utils/dateFormatterHelper';
import classes from './announcement.module.scss';

function ViewAnnouncement(props) {
    const { oktaAuth } = props;
    useCheckMasterData();
    const navigate = useNavigate();
    const location = useLocation();
    const { userPersona } = useContext(ApplicationContext);
    const [videoDialog, setVideoDialog] = useState(false);
    const path = location.pathname.split('/');
    const AnnouncementId = path[path.length - 1];
    const { announcementList, fetchAnnouncements } = useGetAnnouncements(oktaAuth, userPersona, { AnnouncementId }, AnnouncementId);
    const rowData = location?.state?.data ? JSON.parse(location.state.data) : {};
    useReadAnnouncement(oktaAuth, AnnouncementId, rowData?.MessageRead);

    useEffect(() => {
        fetchAnnouncements();
    }, [AnnouncementId]);

    const [videoUrl, setVideoUrl] = useState('');
    const { fileURL, isFileLoading } = useGetAttachedFiles(
        oktaAuth,
        announcementList[0]?.AttachmentURL || announcementList[0]?.AttachementURL // spelling mistake in sent api from store-proc
    );

    const goBackHandler = () => {
        navigate(routes[routesName.ANNOUNCEMENT].getRoute());
    };
    const { getTextTranslated } = useLang();
    const { locale } = useGetLocale();
    const expirationDate = announcementList[0]?.ExpirationDate || '';

    return (
        <DrcContainer heading={getTextTranslated('View Announcement', NAMESPACE.ANNOUNCEMENT)} goBackHandler={goBackHandler}>
            <Container hideRoute>
                <Card sx={{ padding: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid item container alignItems="center" justifyContent="space-between" xs={12}>
                            <Typography variant="subtitle2">{announcementList[0]?.TextHeader}</Typography>
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid item container xs={12} alignItems="center" nameSpacing={NAMESPACE.ANNOUNCEMENT} className={classes.fieldsContainer}>
                            <TypographyTranslated variant="subtitle2">{'Target Criteria'}</TypographyTranslated> :{' '}
                            <Typography variant="body2">{announcementList[0]?.TargetCriteria}</Typography>
                        </Grid>
                        <Divider className={classes.divider} />
                        {showAttachmentFeature() === true && (
                            <Fragment>
                                <ViewAttachments
                                    fileURL={fileURL}
                                    setVideoDialog={setVideoDialog}
                                    setVideoUrl={setVideoUrl}
                                    isFileLoading={isFileLoading}
                                />
                                <Divider className={classes.divider} />
                            </Fragment>
                        )}

                        <Grid item xs={12} minHeight={200} margin={4}>
                            {announcementList[0] && isJsonString(announcementList[0]?.TextBody) ? (
                                <DriscollsRichText defaultValue={announcementList[0]?.TextBody} readOnly={true} />
                            ) : (
                                <Typography variant="body2">{announcementList[0]?.TextBody}</Typography>
                            )}
                        </Grid>
                        <Divider className={classes.divider} />
                        <Grid item container alignItems="center" justifyContent="space-between" xs={12}>
                            <Grid item className={classes.fieldsContainer}>
                                <TypographyTranslated variant="subtitle2" nameSpacing={NAMESPACE.ANNOUNCEMENT}>
                                    {'Recipient Type'}
                                </TypographyTranslated>{' '}
                                : {announcementList[0]?.RecipientType}
                            </Grid>
                            <Grid item className={classes.fieldsContainer}>
                                <TypographyTranslated variant="subtitle2" nameSpacing={NAMESPACE.ANNOUNCEMENT}>
                                    {'Expires On'}
                                </TypographyTranslated>
                                : {nonConventionalDateFormatter(expirationDate, locale)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Container>
            <DrcDialog
                title={getTextTranslated('Currently Playing', NAMESPACE.ANNOUNCEMENT)}
                open={videoDialog}
                isConfirmation={true}
                buttons={
                    <TranslatedDrcButton onClick={() => setVideoDialog(false)} nameSpacing={NAMESPACE.COMMON}>
                        {'Close'}
                    </TranslatedDrcButton>
                }
            >
                <DrcVideoPlayer url={videoUrl} />
            </DrcDialog>
        </DrcContainer>
    );
}

export default withOktaAuth(ViewAnnouncement);
