import { ApplicationPage, ApplicationPageTitle, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import { TUIPersonaInfo, TUIUser, UsersPageEntryPoint } from '@driscollsinc/users-management-module-ui';
import { useOktaAuth } from '@okta/okta-react';
import DuExcelUtilities from 'components/shared/Utilities/DuExcelUtilities';
import { EXPORT_USER_FILE_NAME, EXPORT_USERS_COLUMNS } from 'constants/v2/UserManageConstants';
import ApplicationContext from 'context/ApplicationContext';
import useLang from 'hooks/useLang';
import useUserManagement from 'hooks/v2/useUserManagement';
import { NAMESPACE } from 'i18n';
import React, { useCallback, useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import '../../../index.css';
import { routes, routesName } from '../../../routes/routes';

const UsersPage = () => {
    const navigate = useNavigate();
    const { oktaAuth } = useOktaAuth();
    const { loading, users, getAllUsers, deleteUser, totalNofOfUsers, deletePersonaFromUser, exportUsers } = useUserManagement(oktaAuth);
    const { setShowToast, userPersona, userProfile } = useContext(ApplicationContext);
    const [currentPage, setCurrentPage] = useState(1);
    const { getTextTranslated } = useLang();

    useCallback(() => {
        getUserData(1, {});
    }, []);

    const getUserData = async (pageNumber = 1, requestPayload) => {
        const manageUserPayload = {
            RequestorPersonaId: userPersona,
            Offset: pageNumber == 1 ? 0 : (pageNumber - 1) * 50 + 1,
            Limit: 50,
            GroupByUser: true,
            ...requestPayload
        };
        await getAllUsers(manageUserPayload);
    };
    const handlePageChange = (value) => {
        setCurrentPage(value);
        pageChanged(value);
    };

    const pageChanged = (value) => {
        getUserData(value, {});
    };
    const onDeletePersona = async (userId, persona, userType) => {
        if (userId) {
            const deletePersonaResponse = await deletePersonaFromUser(userId, persona, userType, userProfile.profile.email);
            if (deletePersonaResponse && deletePersonaResponse.ErrorCode === 0) {
                getUserData(1, {});
                setShowToast({ toastMessage: 'Persona deleted Successfully!' });
            } else {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Failed to delete!' });
            }
        }
    };

    const onSearchUser = (search) => {
        getUserData(1, { GenericFilter: search });
    };

    const onAddPersonas = (userId: string, personas: TUIPersonaInfo[], userType: string) => {
        const personaIds = personas.map((p) => p.id).toString();
        navigate(routes[routesName.ADD_PERSONA].getRoute(userId, personaIds, userType));
    };

    const onDeleteUser = async (user: TUIUser) => {
        if (user.original) {
            const userId = user.original.id;
            const deleteUserResponse = await deleteUser(userId, userProfile.profile.email);
            if (deleteUserResponse && deleteUserResponse.ErrorCode === 0) {
                getUserData(1, {});
                setShowToast({ toastMessage: 'User deleted Successfully!' });
            } else {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Failed to delete!' });
            }
        }
    };

    const downloadRecords = async (pageNumber: number, limit: number) => {
        const manageUserPayload = {
            RequestorPersonaId: userPersona,
            Offset: pageNumber == 1 ? 0 : (pageNumber - 1) * 50 + 1,
            Limit: limit,
            GroupByUser: true
        };
        const data = await exportUsers(manageUserPayload);
        exportUsersList(data);
    };

    const exportUsersList = (data) => {
        DuExcelUtilities.Write(EXPORT_USER_FILE_NAME, EXPORT_USERS_COLUMNS, data);
    };

    return (
        <ApplicationPage className="gepApplicationPage">
            <Helmet>
                <title>GEP : {getTextTranslated('User Management', NAMESPACE.COMMON)}</title>
            </Helmet>
            <ApplicationPageTitle
                title={{ text: 'Users', nameSpace: NAMESPACE.USERS }}
                description={{ text: 'Start from here to manage users and personas', nameSpace: NAMESPACE.USERS }}
            />
            <UsersPageEntryPoint
                onInviteUser={() => {
                    navigate(routes[routesName.INVITE_USER].getRoute());
                }}
                onPageChanged={handlePageChange}
                onDeletePersona={onDeletePersona}
                onAddPersona={onAddPersonas}
                onEditPersona={(userId, personaId) => {
                    navigate(routes[routesName.EDIT_USER_PERSONA].getRoute(userId, personaId));
                }}
                data={users}
                onDelete={onDeleteUser}
                selectedPage={currentPage}
                itemPerPage={50}
                loading={loading}
                onSearch={onSearchUser}
                exportCurrentPageHandler={() => downloadRecords(currentPage, 50)}
                exportAllHandler={() => downloadRecords(1, totalNofOfUsers)}
                totalItems={totalNofOfUsers === 0 ? 1 : totalNofOfUsers}
                loggedInUserEmail={userProfile.profile.email}
            />
        </ApplicationPage>
    );
};

export default UsersPage;
