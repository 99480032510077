import { reacti18next } from '@driscollsinc/style-guide-web2.0';
import { allNameSpaces, NAMESPACE } from 'i18n';

export enum Lang {
    ENGLISH = 'en',
    SPANISH = 'es',
    FRENCH = 'fr'
}

const useLang = () => {
    const { useTranslation } = reacti18next;
    const { t, i18n } = useTranslation(allNameSpaces);
    const changeLanguage = (lang: Lang) => {
        i18n.changeLanguage(lang);
    };

    const getCurrentLanguageSelected = () => {
        return <'en' | 'es' | 'fr'>i18n.language;
    };

    const getTextTranslated = (text, nameSpacing = NAMESPACE.COMMON) => {
        return t(text, { ns: [nameSpacing] });
    };

    return {
        changeLanguage,
        getCurrentLanguageSelected,
        getTextTranslated
    };
};

export default useLang;

export const getTxtTranslated = (text, nameSpacing = NAMESPACE.COMMON) => {
    const { useTranslation } = reacti18next;
    const { t } = useTranslation(allNameSpaces);
    return t(text, { ns: [nameSpacing] });
};
