import { Toaster } from '@driscollsinc/users-management-module-ui';
import { withOktaAuth } from '@okta/okta-react';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import DrcPageNotFound from 'components/shared/drc/DrcPageNotFound';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { ROUTES } from 'constants/routes';
import DrcContainer from 'layouts/dashboard/DrcContainer';
import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import AddFeature from './AddFeature';
import FeatureManagement from './FeatureManagement';
import styles from './FeatureManagement.module.scss';

const PersonaManagementRoutes = () => {
    const navigate = useNavigate();

    return (
        <PersonaBaseProtect
            personasAllowed={[Persona.SUPER_ADMIN, Persona.IT_ADMIN]}
            featureName={FEATURES.FEATURE_MANAGEMENT}
            access="Write"
            forbiddenComponent={<DrcPageNotFound />}
        >
            <DrcContainer heading="Feature Management" goBackHandler={() => navigate(`${ROUTES.ROOT}${ROUTES.FEATURE_MANAGEMENT}`)}>
                <div className={styles.personaModuleContainer}>
                    <Toaster>
                        <Routes>
                            <Route path="" element={<FeatureManagement />} />
                            <Route path={routes[routesName.EDIT_FEATURE].path} element={<AddFeature />} />
                        </Routes>
                    </Toaster>
                </div>
            </DrcContainer>
        </PersonaBaseProtect>
    );
};

export default withOktaAuth(PersonaManagementRoutes);
