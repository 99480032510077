import { parsePhoneNumberFromString } from '@driscollsinc/style-guide-web2.0';
import { ENTITY, TUICompanyEntity, TUIDistrictEntity, TUIEntitiesAvailable, TUISelection } from '@driscollsinc/users-management-module-ui';
import { ICompanyObject, IDistrictObject, IRanchObject } from 'components/usermanagement/UserManagementTypes';
import { ApplicationConstants } from 'constants/v2/ApplicationConstants';
import ApplicationContext from 'context/ApplicationContext';
import useLang from 'hooks/useLang';
import { useContext } from 'react';
import { InviteUserPayload, TAssignments, TEntity, TInternalUserProfile, TUIEntity } from 'types/v2/userManagement';

const useFormatDataStructure = () => {
    const { getCurrentLanguageSelected } = useLang();
    const { userProfile } = useContext(ApplicationContext);

    const getDistricts = (districts: Array<IDistrictObject>, companies?: Array<ICompanyObject>) => {
        const districtEntities: TUIEntitiesAvailable = {
            rootType: ENTITY.DISTRICT,
            available: {}
        };

        districts.forEach((district) => {
            const districtCode = district.DistrictCode;
            districtEntities.available[districtCode] = {
                loading: false,
                entity: {
                    entityType: ENTITY.DISTRICT,
                    label: district.DistrictName + ' - ' + district.DistrictCode,
                    value: districtCode,
                    showCompanies: true,
                    showDistrictCommodity: true,
                    commoditiesSelected: [],
                    companies: {}
                }
            };
            // it district contains companies list
            district.Companies &&
                district.Companies.forEach((company) => {
                    const growerNbr = company.Code;
                    districtEntities.available[districtCode].entity.companies[growerNbr] = {
                        loading: false,
                        entity: {
                            entityType: ENTITY.RANCH,
                            label: growerNbr + ' - ' + company.Name,
                            value: growerNbr
                        }
                    };
                });
        });

        companies &&
            companies.forEach((company) => {
                const districtCode = company.DistrictCode;
                const growerNbr = company.CompanyNbr;
                if (districtEntities.available[districtCode]) {
                    districtEntities.available[districtCode].entity.companies[growerNbr] = {
                        loading: false,
                        entityType: ENTITY.COMPANY,
                        entity: {
                            label: company.CompanyNbr + ' - ' + company.CompanyName,
                            value: growerNbr
                        }
                    };
                }
            });

        return districtEntities;
    };

    const getCompanies = (companies: Array<ICompanyObject>, ranches?: Array<IRanchObject>) => {
        const companyEntities: TUIEntitiesAvailable = {
            rootType: ENTITY.COMPANY,
            available: {}
        };

        companies.forEach((company) => {
            const growerNbr = company.Code ?? company.CompanyNbr;
            if (!companyEntities.available[growerNbr]) {
                companyEntities.available[growerNbr] = {
                    loading: false,
                    entity: {
                        entityType: ENTITY.COMPANY,
                        label: company.CompanyNbr + ' - ' + company.CompanyName ?? growerNbr + ' - ' + company.CompanyName,
                        value: growerNbr,
                        id: company.Id,
                        ranches: {}
                    }
                };
            }

            // it company contains ranches list
            company.Ranches &&
                company.Ranches.forEach((ranch) => {
                    const ranchNbr = ranch.Code.toString();
                    companyEntities.available[growerNbr].entity.ranches[ranchNbr] = {
                        loading: false,
                        entity: {
                            entityType: ENTITY.RANCH,
                            label: ranchNbr + ' - ' + ranch.Name,
                            value: ranchNbr
                        }
                    };
                });
        });

        // if ranches are coming from separate array
        ranches &&
            ranches.forEach((ranch) => {
                const growerNbr = ranch.GrowerNbr ?? ranch.CompanyNbr;
                const ranchNbr = ranch.RanchNbr ?? ranch.RanchNumber;
                if (companyEntities.available[growerNbr]) {
                    companyEntities.available[growerNbr].entity.ranches[ranchNbr] = {
                        loading: false,
                        entity: {
                            entityType: ENTITY.RANCH,
                            label: ranchNbr + ' - ' + ranch.Name,
                            value: ranchNbr
                        }
                    };
                }
            });
        return companyEntities;
    };

    const mapInviteUserReqPayload = (
        formData,
        persona,
        internalUsersList: Array<{ label: string; value: string; internalUserProfile: TInternalUserProfile }>,
        countryCode
    ): InviteUserPayload => {
        const userType = persona.type.external === true ? 'external' : 'internal';
        let selectedOktaId = '';
        let selectedFirstName = '';
        let selectedLastName = '';
        if (!persona.type.external) {
            const obj = internalUsersList.find((value) => value.value === formData.persona.sections?.personalInfo.email.value);
            selectedOktaId = obj?.internalUserProfile.oktaId;
            selectedFirstName = obj?.internalUserProfile.firstName;
            selectedLastName = obj?.internalUserProfile.lastName;
        }
        const profileEmail = userProfile.profile.email;
        const mappedPayload: InviteUserPayload = {
            PersonaDetails: {
                UserOktaId: selectedOktaId
            },
            UserType: userType,
            UserOktaId: selectedOktaId,
            ApplicationName: ApplicationConstants.APPLICATION_NAME,
            CognitoGroup: persona.cognitoGroup,
            PersonaType: { Label: formData.persona.label, Value: formData.persona.value },
            UserId: '',
            AndroidAppUrl: ApplicationConstants.ANDROID_URL,
            AppleAppUrl: ApplicationConstants.APPLE_URL,
            PersonalInfo: {
                FirstName: userType === 'internal' ? selectedFirstName : formData.persona.sections?.personalInfo.firstName.value,
                LastName: userType === 'internal' ? selectedLastName : formData.persona.sections?.personalInfo.lastName.value,
                Email: formData.persona.sections?.personalInfo.email.value,
                Phone: formData.persona.sections?.personalInfo.phone.value,
                Country: formData.persona.sections?.personalInfo.country.value,
                PreferredLng: formData.persona.sections?.personalInfo.preferredLng.value,
                Status: true,
                Role: formData.persona.sections?.personalInfo.role.value,
                LoginMode: formData.persona.sections?.personalInfo.loginMode.value,
                DefaultContact: formData.persona.sections?.personalInfo.defaultContact.value
                    ? formData.persona.sections?.personalInfo.defaultContact.value
                    : false,
                OtpOnWhatsApp: countryCode === 'us' ? formData.persona.sections?.personalInfo.otpOnWhatsApp.value : true
            },
            AuthId: '',
            Lang: getCurrentLanguageSelected(),
            Reports: formData.reportsSelected.map((report) => ({
                ReportId: report.value,
                ReportURL: report.reportUrl
            })),
            Assignments: formData.theAssignments ? mapAssignments(formData.theAssignments, persona) : null,
            LastModifiedBy: profileEmail,
            CreatedBy: profileEmail
        };

        return mappedPayload;
    };

    const mapAssignments = (assignment, persona) => {
        const assignments = {} as TAssignments;
        const assign = {} as Record<string, { Entity: TEntity }>;
        let selectionType = '';
        let showDistrictCommodity = false;
        let showRanches = false;
        let showCompanies = false;
        if (persona.sections) {
            if (persona.sections?.assignments) {
                selectionType = persona.sections?.assignments.selectionType;
                showDistrictCommodity = persona.sections?.assignments.showDistrictCommodity;
                if (selectionType === ENTITY.COMPANY) showRanches = persona.sections?.assignments.showRanches;
                if (selectionType === ENTITY.DISTRICT) showCompanies = persona.sections?.assignments.showCompanies;
            }
        }
        Object.keys(assignment.assign).forEach((assignmentId: string) => {
            const entity = assignment.assign[assignmentId];
            const mappedRanches = {};
            const mappedCompanies = {};
            const mappedCommodities = [];
            if (showRanches) {
                Object.keys(entity.entity.ranches).forEach((key) => {
                    const item = entity.entity.ranches[key];
                    const ranch = item.entity;
                    mappedRanches[key] = { Entity: { EntityType: item.entity.entityType, Label: ranch.label, Value: ranch.value } };
                });
            }
            if (showCompanies) {
                Object.keys(entity.entity.companies).forEach((key) => {
                    const item = entity.entity.companies[key];
                    const company = item.entity;
                    mappedCompanies[key] = { Entity: { EntityType: item.entity.entityType, Label: company.label, Value: company.value } };
                });
            }
            if (showDistrictCommodity) {
                Object.keys(entity.entity.commoditiesSelected).forEach((key) => {
                    mappedCommodities.push(entity.entity.commoditiesSelected[key]);
                });
            }
            const entityObject: TEntity = {
                EntityType: entity.entity.entityType,
                Label: entity.entity.label,
                Value: entity.entity.value,
                ...(showDistrictCommodity && {
                    CommoditiesSelected: mappedCommodities
                }),
                ...(showRanches && {
                    Ranches: mappedRanches
                }),
                ...(showCompanies && {
                    Companies: mappedCompanies
                })
            };
            assign[assignmentId] = { Entity: entityObject };
        });
        assignments.SelectionType = selectionType;
        assignments.Assign = assign;
        return assignments;
    };

    const editUserDataMapping = (userPersonaDetails, personaDetails) => {
        if (personaDetails?.sections) {
            const updatedPersonalInfo = { ...personaDetails.sections.personalInfo };
            if (userPersonaDetails.PersonalInfo) {
                updatedPersonalInfo.email.value = userPersonaDetails.PersonalInfo.Email;
                updatedPersonalInfo.firstName.value = userPersonaDetails.PersonalInfo.FirstName;
                updatedPersonalInfo.lastName.value = userPersonaDetails.PersonalInfo.LastName;
                updatedPersonalInfo.preferredLng.value = { label: userPersonaDetails.PersonalInfo.Lang, value: userPersonaDetails.PersonalInfo.Lang };
                updatedPersonalInfo.phone.value = userPersonaDetails.PersonalInfo.Phone;
                updatedPersonalInfo.loginMode.value = {
                    label: userPersonaDetails.PersonalInfo.DefaultUser,
                    value: userPersonaDetails.PersonalInfo.DefaultUser
                };
                updatedPersonalInfo.otpOnWhatsApp.value = userPersonaDetails.PersonalInfo.OtpOnWhatsApp;
            }
            personaDetails.sections.personalInfo = updatedPersonalInfo;

            return personaDetails;
        }
    };

    const editUserAssignmentsMapping = (userPersonaDetails, selectionType) => {
        const assign = {} as Record<string, { loading: boolean; entity: TUIEntity }>;
        if (userPersonaDetails?.Assignments.Assign) {
            Object.keys(userPersonaDetails?.Assignments.Assign).forEach((assignmentId: string) => {
                const entity = userPersonaDetails?.Assignments.Assign[assignmentId].Entity;
                const retEntity: TUIEntity = {
                    entityType: entity.EntityType,
                    label: entity.Value + ' - ' + entity.Label,
                    value: entity.Value
                };
                if (selectionType === ENTITY.DISTRICT) {
                    const district = retEntity as TUIDistrictEntity;
                    district.companies = {};
                    district.commoditiesSelected = [];
                    const theCompanies = entity.Companies;
                    const theCommodities = entity.Berrytypes;
                    if (theCompanies) {
                        Object.keys(theCompanies).forEach((companyId) => {
                            const company = theCompanies[companyId].Entity;
                            district.companies[companyId] = {
                                loading: false,
                                entity: { entityType: ENTITY.COMPANY, label: company.Value + ' - ' + company.Label, value: company.Value }
                            };
                        });
                    }
                    if (theCommodities) {
                        Object.keys(theCommodities).forEach((commodityId) => {
                            const commodity = theCommodities[commodityId].Entity;
                            district.commoditiesSelected.push({ Id: commodity.Value, Name: commodity.Label });
                        });
                    }
                    assign[entity.Value] = { loading: false, entity: district };
                }
                if (selectionType === ENTITY.COMPANY) {
                    const company = retEntity as TUICompanyEntity;
                    company.ranches = {};
                    const theRanches = entity.Ranches;
                    if (theRanches) {
                        Object.keys(theRanches).forEach((ranchId) => {
                            const ranch = theRanches[ranchId].Entity;
                            company.ranches[ranchId] = {
                                loading: false,
                                entity: { entityType: ENTITY.RANCH, label: ranch.Value + ' - ' + ranch.Label, value: ranch.Value }
                            };
                        });
                    }
                    assign[entity.Value] = { loading: false, entity: company };
                }
            });
        }
        return assign;
    };
    const addUserProfileMapping = (personas, userProfilePersonaDetails) => {
        const copyPersonas = JSON.parse(JSON.stringify(personas));
        if (userProfilePersonaDetails) {
            for (const [key, persona] of Object.entries(personas)) {
                const typedPersona = persona as TUISelection;
                const userProfile = userProfilePersonaDetails.PersonalInfo;
                const updatedPersonalInfo = { ...typedPersona.sections.personalInfo };
                updatedPersonalInfo.email.value = userProfile.Email;
                updatedPersonalInfo.firstName.value = userProfile.FirstName;
                updatedPersonalInfo.lastName.value = userProfile.LastName;
                updatedPersonalInfo.preferredLng.value = { label: userProfile.Lang, value: userProfile.Lang };
                updatedPersonalInfo.phone.value = userProfile.Phone;
                updatedPersonalInfo.loginMode.value = { label: userProfile.DefaultUser, value: userProfile.DefaultUser };
                typedPersona.sections.personalInfo = updatedPersonalInfo;

                copyPersonas[key] = typedPersona;
            }
        }
        return copyPersonas;
    };

    const getPayloadEditAssignmentsReports = (formData, persona, userId): InviteUserPayload => {
        const userType = persona.type.external === true ? 'external' : 'internal';
        let parseCountryData;
        if (formData.persona.sections?.personalInfo.phone.value)
            parseCountryData = parsePhoneNumberFromString(formData.persona.sections?.personalInfo.phone.value);
        const countryCode = parseCountryData?.country;
        const profileEmail = userProfile.profile.email;
        const mappedPayload: InviteUserPayload = {
            PersonaDetails: {
                UserOktaId: userId
            },
            UserType: userType,
            UserOktaId: userId,
            ApplicationName: ApplicationConstants.APPLICATION_NAME,
            CognitoGroup: persona.cognitoGroup,
            PersonaType: { Label: formData.persona.label, Value: formData.persona.value },
            UserId: '',
            AndroidAppUrl: ApplicationConstants.ANDROID_URL,
            AppleAppUrl: ApplicationConstants.APPLE_URL,
            PersonalInfo: {
                FirstName: formData.persona.sections?.personalInfo.firstName.value,
                LastName: formData.persona.sections?.personalInfo.lastName.value,
                Email: formData.persona.sections?.personalInfo.email.value,
                Phone: formData.persona.sections?.personalInfo.phone.value,
                Country: formData.persona.sections?.personalInfo.country.value,
                PreferredLng: formData.persona.sections?.personalInfo.preferredLng.value.value,
                Status: true,
                Role: formData.persona.sections?.personalInfo.role.value,
                LoginMode: formData.persona.sections?.personalInfo.loginMode.value.value,
                DefaultContact: formData.persona.sections?.personalInfo.defaultContact.value
                    ? formData.persona.sections?.personalInfo.defaultContact.value
                    : false,
                OtpOnWhatsApp: countryCode === 'US' ? formData.persona.sections?.personalInfo.otpOnWhatsApp.value : true
            },
            AuthId: userId,
            Lang: getCurrentLanguageSelected(),
            Reports: formData.reportsSelected.map((report) => ({
                ReportId: report.value,
                ReportURL: report.reportUrl
            })),
            Assignments: formData.theAssignments ? mapAssignments(formData.theAssignments, persona) : null,
            LastModifiedBy: profileEmail,
            CreatedBy: profileEmail
        };

        return mappedPayload;
    };

    const addPersonaReqPayload = (formData, persona, userOktaId): InviteUserPayload => {
        let parseCountryData;
        if (formData.persona.sections?.personalInfo.phone.value)
            parseCountryData = parsePhoneNumberFromString(formData.persona.sections?.personalInfo.phone.value);
        const countryCode = parseCountryData?.country;
        const userType = persona.type.external === true ? 'external' : 'internal';
        const profileEmail = userProfile.profile.email;
        const mappedPayload: InviteUserPayload = {
            PersonaDetails: {
                UserOktaId: userOktaId
            },
            UserType: userType,
            UserOktaId: userOktaId,
            ApplicationName: ApplicationConstants.APPLICATION_NAME,
            CognitoGroup: persona.cognitoGroup,
            PersonaType: { Label: formData.persona.label, Value: formData.persona.value },
            UserId: '',
            AndroidAppUrl: ApplicationConstants.ANDROID_URL,
            AppleAppUrl: ApplicationConstants.APPLE_URL,
            PersonalInfo: {
                FirstName: formData.persona.sections?.personalInfo.firstName.value,
                LastName: formData.persona.sections?.personalInfo.lastName.value,
                Email: formData.persona.sections?.personalInfo.email.value,
                Phone: formData.persona.sections?.personalInfo.phone.value,
                Country: formData.persona.sections?.personalInfo.country.value,
                PreferredLng: formData.persona.sections?.personalInfo.preferredLng.value.value,
                Status: true,
                Role: formData.persona.sections?.personalInfo.role.value,
                LoginMode: formData.persona.sections?.personalInfo.loginMode.value.value,
                DefaultContact: formData.persona.sections?.personalInfo.defaultContact.value
                    ? formData.persona.sections?.personalInfo.defaultContact.value
                    : false,
                OtpOnWhatsApp: countryCode === 'US' ? formData.persona.sections?.personalInfo.otpOnWhatsApp.value : true
            },
            AuthId: '',
            Lang: getCurrentLanguageSelected(),
            Reports: formData.reportsSelected.map((report) => ({
                ReportId: report.value,
                ReportURL: report.reportUrl
            })),
            Assignments: formData.theAssignments ? mapAssignments(formData.theAssignments, persona) : null,
            UpdatedBy: profileEmail,
            CreatedBy: profileEmail
        };

        return mappedPayload;
    };
    return {
        getDistricts,
        getCompanies,
        mapInviteUserReqPayload,
        editUserDataMapping,
        addUserProfileMapping,
        editUserAssignmentsMapping,
        getPayloadEditAssignmentsReports,
        addPersonaReqPayload
    };
};

export default useFormatDataStructure;
