import { Call } from '@driscollsinc/driscolls-display-rules';
import { useLang } from '@driscollsinc/style-guide-web2.0';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import { IUserSetup } from 'components/appinitializer/v2/IUserSetup';
import IconComponent from 'components/v2/IconComponent';
import { ROUTES } from 'constants/routes';
import useGetApiTokens from 'hooks/useGetApiTokens';
import { NAMESPACE } from 'i18n';
import useApis from 'services/api/v2/useApis';
import { TNavigationData } from 'types/v2/config';
import style from './config.module.scss';

const useConfig = () => {
    const { getBaseURLMuleApis } = useApis();
    const { getTokenAndId } = useGetApiTokens();
    const { getTextTranslated } = useLang([NAMESPACE.COMMON]);
    const getUserFeaturesByPersona = async (oktaAuth: any, personaId?: string) => {
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            let url = `${getBaseURLMuleApis()}users/${userOktaId}/personaid/features`;
            if (personaId) {
                url += `?personaid=${personaId}`;
            }
            const data = await Call(
                {
                    name: 'UserFeatures',
                    url: url,
                    method: 'GET',
                    options: {}
                },
                token
            );

            const apiData = data.display.UserSetup as IUserSetup;
            return apiData;
        } catch (error) {
            return null;
        }
    };
    const getNavigationByConfig = (userSetup: IUserSetup) => {
        const tooltipContentClass = style.tooltipContent;
        const items: Array<TNavigationData> = [];
        if (userSetup?.Features && FEATURES.DASHBOARD in userSetup.Features && userSetup.Features[FEATURES.DASHBOARD].Read) {
            items.push({
                description: getTextTranslated('Various Information.', NAMESPACE.COMMON),
                isEnabled: window.config.dashboardEnabled === 'true',
                path: ROUTES.DASHBOARD,
                ModuleIcon: () => IconComponent({ iconName: 'dashboard' }),
                title: getTextTranslated('Dashboard', NAMESPACE.DASHBOARD),
                tooltipContentClass
            });
        }
        if (userSetup?.Features && FEATURES.USER_MANAGEMENT in userSetup.Features && userSetup.Features[FEATURES.USER_MANAGEMENT].Read) {
            items.push({
                title: getTextTranslated('User Management', NAMESPACE.COMMON),
                description: getTextTranslated(
                    'Manage User including Invite, Edit and View with assigned Districts, Companies and Ranches.',
                    NAMESPACE.COMMON
                ),
                path: ROUTES.USER_MANAGEMENT,
                ModuleIcon: () => IconComponent({ iconName: 'user-management' }),
                isEnabled: window.config.userManagementEnabled === 'true',
                tooltipContentClass
            });
        }
        if (userSetup?.Features && FEATURES.PTI in userSetup.Features && userSetup.Features[FEATURES.PTI].Read) {
            items.push({
                title: 'PTI',
                description: '',
                path: ROUTES.PTI,
                ModuleIcon: () => IconComponent({ iconName: 'pti' }),
                isEnabled: window.config.ptiEnabled === 'true',
                tooltipContentClass
            });
        }
        if (userSetup?.Features && FEATURES.REPORTS in userSetup.Features && userSetup.Features[FEATURES.REPORTS].Read) {
            items.push({
                title: getTextTranslated('Reports', NAMESPACE.COMMON),
                description: '',
                path: ROUTES.REPORTS,
                ModuleIcon: () => IconComponent({ iconName: 'reports' }),
                isEnabled: window.config.reportsEnabled === 'true',
                tooltipContentClass
            });
        }
        if (userSetup?.Features && FEATURES.ANNOUNCEMENTS in userSetup.Features && userSetup.Features[FEATURES.ANNOUNCEMENTS].Read) {
            items.push({
                title: getTextTranslated('Announcement', NAMESPACE.COMMON),
                description: getTextTranslated('Send and View the communication with Growers.', NAMESPACE.COMMON),
                path: ROUTES.ANNOUNCEMENT,
                ModuleIcon: () => IconComponent({ iconName: 'announcements' }),
                isEnabled: window.config.communicationEnabled === 'true',
                tooltipContentClass
            });
        }
        if (userSetup?.Features && FEATURES.LIBRARY in userSetup.Features && userSetup.Features[FEATURES.LIBRARY].Read) {
            items.push({
                title: getTextTranslated('Library', NAMESPACE.COMMON),
                description: '',
                path: ROUTES.DOCUMENT_LIBRARY,
                ModuleIcon: () => IconComponent({ iconName: 'library' }),
                isEnabled: window.config.libraryEnabled === 'true',
                tooltipContentClass
            });
        }
        if (userSetup?.Features && FEATURES.SUPPORT in userSetup.Features && userSetup.Features[FEATURES.SUPPORT].Read) {
            items.push({
                title: getTextTranslated('Support', NAMESPACE.COMMON),
                description: '',
                path: ROUTES.SUPPORT,
                ModuleIcon: () => IconComponent({ iconName: 'support' }),
                isEnabled: window.config.supportEnabled === 'true',
                tooltipContentClass
            });
        }
        if (userSetup?.Features && FEATURES.ANALYTICS in userSetup.Features && userSetup.Features[FEATURES.ANALYTICS].Read) {
            items.push({
                title: getTextTranslated('Analytics', NAMESPACE.COMMON),
                description: '',
                path: ROUTES.ANALYTICS,
                ModuleIcon: () => IconComponent({ iconName: 'analytics' }),
                isEnabled: window.config.analyticsEnabled === 'true',
                tooltipContentClass
            });
        }
        if (userSetup?.Features && FEATURES.WEATHER in userSetup.Features && userSetup.Features[FEATURES.WEATHER].Read) {
            items.push({
                title: getTextTranslated('Weather', NAMESPACE.COMMON),
                description: getTextTranslated('WeatherDescription', NAMESPACE.COMMON),
                path: ROUTES.WEATHER,
                ModuleIcon: () => IconComponent({ iconName: 'weather-overcast' }),
                isEnabled: window.config.weatherEnabled === 'true',
                tooltipContentClass
            });
        }
        if (userSetup?.Features && FEATURES.COMPANIES in userSetup.Features && userSetup.Features[FEATURES.COMPANIES].Read) {
            items.push({
                title: 'Companies',
                description: 'List of companies with features enabled available',
                path: ROUTES.COMPANIES,
                ModuleIcon: () => IconComponent({ iconName: 'companies' }),
                isEnabled: window.config.companiesEnabled === 'true'
            });
        }
        items.push({
            title: getTextTranslated('BerryConnect', NAMESPACE.COMMON),
            description: getTextTranslated('BerryConnectDescription', NAMESPACE.COMMON),
            path: ROUTES.BERRY_CONNECT,
            ModuleIcon: () => IconComponent({ iconName: 'berry-connect' }),
            isEnabled: window.config.berryConnectEnabled === 'true',
            tooltipContentClass
        });
        return items;
    };

    return { getUserFeaturesByPersona, getNavigationByConfig };
};

export default useConfig;
