import React, { PropsWithChildren, useContext } from 'react';

import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import ApplicationContext from 'context/ApplicationContext';
import ApplicationContextV2 from 'context/ApplicationContextV2';
import { PERSONAID } from 'data/config/persona';
import { Navigate } from 'react-router-dom';


// TODO: Once user management v2 completed these values need to be removed
export enum Persona {
    IT_ADMIN = 0,
    SUPER_ADMIN = PERSONAID.SUPERADMIN,
    REGIONAL_ADMIN = PERSONAID.REGIONALADMIN,
    HARVEST_PLANNER = PERSONAID.HARVESTPLANNER,
    GROWER_ADMIN = PERSONAID.GROWERADMIN,
    RANCH_ADMIN = PERSONAID.RANCHADMIN,
    RANCH_PLANNER = PERSONAID.RANCHPLANNER,
    QA_ADMIN = PERSONAID.QAADMIN,
    PALLET_BUILDER = PERSONAID.PALLETBUILDER,
    PTI_APP = PERSONAID.PTIAPP,
    INVALID_PERSONA = PERSONAID.INVALIDPERSONA,
    GROWER_USER = PERSONAID.GROWERUSER,
    PORTAL_USER = PERSONAID.PORTALUSER,
    SETTLEMENT_USER = PERSONAID.SETTLEMENTUSER,
    QUALITY_VIEWER = PERSONAID.QUALITYVIEWER,
    GEO_WEATHER = PERSONAID.GEOWEATHER,
    REPORT_BUILDER = PERSONAID.REPORTBUILDER,
    PTI_USER = PERSONAID.PTIUSER
}

interface PersonaBaseProtectProps {
    personasAllowed: Array<Persona>;
    forbiddenReRoute?: string;
    forbiddenComponent?: React.ReactNode;
    featureName: FEATURES;
    access: 'Read' | 'Write';
}

/**
 * The component checks the persona id and based on the persona ids passed
 * it validate the access.
 * You can pass a reroute path or a component to render in case access is not granted
 *
 * Example
 * <PersonaBaseProtect personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN, Persona.GROWER_ADMIN, Persona.QA_ADMIN]}>
       <Button>Creation</Button>
    </PersonaBaseProtect>
 * @param props {@link PersonaBaseProtectProps}
 * @returns
 */
const PersonaBaseProtect = (props: PropsWithChildren<PersonaBaseProtectProps>) => {
    const { userPersona } = useContext(ApplicationContext);
    const { userSetup } = useContext(ApplicationContextV2);

    if (window.config.uiConfig.v2UI === 'true' && userSetup) {
        if (userSetup?.Features && props.featureName in userSetup.Features && userSetup.Features[props.featureName][props.access])
            return <>{props.children}</>;
        else if (props.forbiddenComponent) {
            return <>{props.forbiddenComponent}</>;
        } else if (props.forbiddenReRoute) {
            return (
                <Navigate to={props.forbiddenReRoute} />
            );
        } else {
            return null;
        }
    } else {
        if (props.personasAllowed.includes(userPersona)) {
            return <>{props.children}</>;
        } else if (props.forbiddenComponent) {
            return <>{props.forbiddenComponent}</>;
        } else if (props.forbiddenReRoute) {
            return (
                <Navigate to={props.forbiddenReRoute} />
            );
        } else {
            return null;
        }
    }
};

export default PersonaBaseProtect;


