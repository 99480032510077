import ImageComponent from 'components/shared/ImageComponent/ImageComponent';
import React from 'react';
import style from './IconComponent.module.scss';

const IconComponent = ({ iconName }: { iconName: string }) => {
    const getIconPath = (iconName: string) => `/icons/navigation/${iconName}.svg`;
    return <ImageComponent className={style.icon} src={getIconPath(iconName)} size={'small'} />;
};

export default IconComponent;
