import {
    ApplicationPage,
    ApplicationPageTitle,
    DriscollsSpinner,
    DriscollsToastSeverity,
    DriscollsVContainer
} from '@driscollsinc/style-guide-web2.0';
import { InviteUserEntryPoint, TUIAssignments, TUIPersona, TUISetup } from '@driscollsinc/users-management-module-ui';
import { useOktaAuth } from '@okta/okta-react';
import { ROUTES } from 'constants/routes';
import ApplicationContext from 'context/ApplicationContext';
import useLang from 'hooks/useLang';
import usePersonaDetails from 'hooks/v2/usePersonaDetails';
import useUserManagement from 'hooks/v2/useUserManagement';
import useFormatDataStructure from 'hooks/v2/utils/useFormatDataStructure';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import useUserManagementService from 'services/v2/useUserManagementService';
import { TInviteUserFormData } from 'types/v2/userManagement';
import '../../../index.css';

const EditUserPersona = () => {
    const { oktaAuth } = useOktaAuth();
    const navigate = useNavigate();
    const { initInviteUserSetup, getAlreadySelectedReport } = useUserManagementService();
    const { updateUserAssignmentsReports } = useUserManagement(oktaAuth);
    const { getUsersPersona } = useUserManagement(oktaAuth);
    const { personaId, userId } = useParams();
    const [setUp, setSetup] = useState<TUISetup>();
    const [personaType, setPersonaType] = useState<{ label: string; value: string } | []>([]);
    const [personaDetails, setPersona] = useState<TUIPersona | null>();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [isLoadingReports] = useState(false);
    const { getPersonaDetails } = usePersonaDetails(oktaAuth);
    const { userPersona, userProfile, setShowToast } = useContext(ApplicationContext);
    const { editUserDataMapping, editUserAssignmentsMapping, getPayloadEditAssignmentsReports } = useFormatDataStructure();
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loadingAssignments, setLoadingAssignments] = useState(false);
    const [assignments, setAssignments] = useState<TUIAssignments | null>(null);
    const [alreadySelectedReports, setAlreadySelectedReports] = useState([]);
    const { getTextTranslated } = useLang();

    const initData = async () => {
        setLoading(true);
        setLoadingAssignments(true);
        const _personaDetails = await getPersonaDetails(String(userPersona), personaId);
        const userPersonaDetails = await getUsersPersona(userId, personaId, userProfile.profile.email);
        setAlreadySelectedReports(userPersonaDetails.Reports);
        setPersonaType({ label: userPersonaDetails.PersonaType.Label, value: userPersonaDetails.PersonaType.Value });
        const mappedUserData = editUserDataMapping(userPersonaDetails, _personaDetails);
        const { error: _error, setUp: _setUp } = await initInviteUserSetup(mappedUserData?.sections, mappedUserData?.type);
        const assign = {
            selectionType: _personaDetails.sections?.assignments?.selectionType,
            assign: editUserAssignmentsMapping(userPersonaDetails, _personaDetails.sections?.assignments?.selectionType)
        } as TUIAssignments;
        setAssignments(assign);

        setPersona(_personaDetails);
        setError(_error);
        setSetup(_setUp);
        setLoading(false);
        setLoadingAssignments(false);
    };

    useEffect(() => {
        initData();
    }, []);

    const onSubmit = async (formData: TInviteUserFormData) => {
        setLoadingSubmit(true);
        try {
            const inviteUserPayload = getPayloadEditAssignmentsReports(formData, personaDetails, userId);
            const updateUserResponse = await updateUserAssignmentsReports(inviteUserPayload, userId, personaId, userProfile.profile.email);
            if (updateUserResponse && updateUserResponse.ErrorCode === 0) {
                setShowToast({ toastMessage: 'Save Successful' });
                navigate(`${ROUTES.ROOT}${ROUTES.USER_MANAGEMENT}`);
            } else {
                setShowToast({
                    severity: DriscollsToastSeverity.ERROR,
                    toastMessage: String(updateUserResponse.ErrorCode)
                });
            }
        } catch (error) {
            console.error('Error occurred while inviting user:', error);
        } finally {
            setLoadingSubmit(false);
        }
    };

    return (
        <ApplicationPage className="gepApplicationPage">
            <ApplicationPageTitle
                title={{ text: 'Edit Persona', nameSpace: NAMESPACE.USERS }}
                description={{ text: 'Personal information cannot be modified', nameSpace: NAMESPACE.USERS }}
            />
            <Helmet>
                <title>GEP : {getTextTranslated('Edit Persona', NAMESPACE.USERS)}</title>
            </Helmet>
            <DriscollsVContainer>
                {loading && <DriscollsSpinner />}
                {!loading && error !== null && <p>Error</p>}
                {!loading && error === null && (
                    <InviteUserEntryPoint
                        isEditUser={true}
                        isLoadingTheAssignments={loadingAssignments}
                        disablePersonaType
                        hidePersonaTypeError
                        disablePersonaInfo
                        assignments={assignments}
                        setUp={setUp}
                        isLoadingPersonalInfo={false}
                        isLoadingReports={isLoadingReports}
                        onCancel={() => {
                            navigate(routes[routesName.USER].getRoute());
                        }}
                        thePersonaTypes={personaType ?? []}
                        thePersona={personaDetails}
                        onSubmitHandler={onSubmit}
                        defaultPersonaType={personaType}
                        onTypeChanged={() => null}
                        isLoadingPersonaTypes={false}
                        loadingConfig={false}
                        loadingSubmit={loadingSubmit}
                        defaultReports={getAlreadySelectedReport(alreadySelectedReports)}
                    />
                )}
            </DriscollsVContainer>
        </ApplicationPage>
    );
};

export default EditUserPersona;
