import Check from '@mui/icons-material/Check';
import { Avatar, Box, Divider, IconButton, MenuItem, Popover, Stack, styled, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { alpha } from '@mui/material/styles';
import { useOktaAuth, withOktaAuth } from '@okta/okta-react';
import useCheckMasterData from 'hooks/useCheckMasterData';
import useMakeDefaultPersona from 'hooks/useMakeDefaultPersona';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TypographyTranslated } from '../../../components/shared/Typography/TypographyTranslated';
import DuAuthenticationUtilities from '../../../components/shared/Utilities/DuAuthenticationUtilities';
import { ROUTES } from '../../../constants/routes';
import ApplicationContext from '../../../context/ApplicationContext';
import { isPersonaAllowedInTheApp, persona } from '../../../data/config/datasetup';

// ----------------------------------------------------------------------
const StyledSubMenu = styled(Box)({
    background: 'white',
    right: '232px',
    top: '147px',
    position: 'fixed',
    borderRadius: '18px 0 0 18px',
    boxShadow: '0px 5px 5px -3px rgb(145 158 171 / 20%), 0px 8px 10px 1px rgb(145 158 171 / 14%), 0px 3px 14px 2px rgb(145 158 171 / 12%)'
});

const AccountPopover = (props) => {
    const { oktaAuth, setPersona } = props;
    const { userPersona, setUserPersona, userPersonas, userProfile = { profile: {} }, setMasterDataInitialized } = useContext(ApplicationContext);
    useCheckMasterData();
    const [open, setOpen] = useState(null);
    const [account, setAccount] = useState({ displayName: '', email: '', char: '', persona: '' });
    const [showSubMenu, setShowSubMenu] = useState(null);
    const personaId = userPersona;
    const navigate = useNavigate();
    const { authState } = useOktaAuth();
    const { makeDefaultPersona } = useMakeDefaultPersona(oktaAuth);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
        setShowSubMenu(null);
    };

    const getLandingPage = (personaId) => persona.find((p) => p.Id === personaId)?.landingPage;

    useEffect(() => {
        const idp = DuAuthenticationUtilities.getAuthProvider();
        if (idp === 'okta') {
            if (authState?.isAuthenticated) {
                const userEmail = authState?.idToken?.claims?.email?.toUpperCase();
                const auth = {
                    displayName: userProfile !== null ? userProfile.profile.displayName : '',
                    email: userEmail.toLowerCase(),
                    char: userEmail.charAt(0)
                };
                setAccount(auth);
            }
        } else {
            const cognitoUser = JSON.parse(localStorage.getItem('cognitouser'));
            const userEmail = cognitoUser?.attributes?.given_name;
            const auth = {
                displayName: userProfile !== null ? userProfile.profile.firstName : '',
                email: cognitoUser?.attributes?.phone_number,
                char: userEmail?.charAt(0)
            };
            setAccount(auth);
        }
    }, [authState]);

    const logout = () => {
        const path = '/LogOut';
        navigate(path);
    };

    const handleMakeDefaultPersona = async (persona) => {
        //defaultPersona is now persisted for a session
        window.history.replaceState(null, null, getLandingPage(persona) || `${ROUTES.ROOT}${ROUTES.DASHBOARD}`);
        localStorage.setItem('defaultPersona', persona);
        setUserPersona(persona);
        setPersona(persona);
        setShowSubMenu(false);
        setMasterDataInitialized(false);
        navigate(0);
    };

    return (
        <>
            <IconButton
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.8)
                        }
                    })
                }}
            >
                <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.lighter }} alt="photoURL">
                    {account.char}
                </Avatar>
            </IconButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            typography: 'body2',
                            borderRadius: 0.75
                        }
                    }
                }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle2" noWrap>
                        {account.displayName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {account.email}
                    </Typography>
                </Box>
                {showSubMenu && (
                    <StyledSubMenu>
                        {(userPersonas || []).map(
                            (option) =>
                                isPersonaAllowedInTheApp(option.PersonaId) && (
                                    <MenuItem
                                        key={option.PersonaId}
                                        onClick={() => {
                                            handleMakeDefaultPersona(option.PersonaId);
                                            handleClose();
                                        }}
                                    >
                                        {personaId === option.PersonaId ? (
                                            <ListItemIcon sx={{ color: (theme) => theme.palette.primary }}>
                                                <Check />
                                            </ListItemIcon>
                                        ) : (
                                            <span style={{ width: 35 }} />
                                        )}
                                        <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{option.PersonaName}</TypographyTranslated>
                                    </MenuItem>
                                )
                        )}
                    </StyledSubMenu>
                )}

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>
                    <MenuItem
                        onClick={() => {
                            navigate(ROUTES.PROFILE);
                            handleClose();
                        }}
                    >
                        <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{'Profile'}</TypographyTranslated>
                    </MenuItem>

                    {(userPersonas || []).length > 1 && (
                        <Typography
                            aria-owns={showSubMenu ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            component={MenuItem}
                            onMouseEnter={(e) => setShowSubMenu(e.currentTarget)}
                            onClick={() => {
                                setShowSubMenu(!showSubMenu);
                                handleClose();
                            }}
                        >
                            <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{'Accounts'}</TypographyTranslated>
                        </Typography>
                    )}
                </Stack>
                <Divider sx={{ borderStyle: 'dashed' }} />

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={logout} sx={{ m: 1 }}>
                    <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{'Logout'}</TypographyTranslated>
                </MenuItem>
            </Popover>
        </>
    );
};

const withContext = (ComposedComponent) =>
    class WithContext extends React.Component {
        constructor(props) {
            super(props);
        }

        static displayName = `WithContext(${ComposedComponent.displayName || ComposedComponent.name || React.Component.name})`;

        static defaultProps = {};

        render() {
            const { children, ...passThroughProps } = this.props;
            return <ComposedComponent {...passThroughProps}>{children}</ComposedComponent>;
        }
    };

export default withContext(withOktaAuth(AccountPopover));
