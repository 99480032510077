import {
    DirectionEnum,
    DriscollsButton,
    DriscollsComponentSwitcher,
    DriscollsDataTable,
    DriscollsEditButton,
    DriscollsHContainer,
    DriscollsMobileList,
    DriscollsToastSeverity,
    DriscollsTranslatedValue,
    DriscollsTrashButton,
    DriscollsVContainer,
    FaIcons,
    Language,
    useLang
} from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import usePB from 'hooks/v2/usePB';
import { NAMESPACE } from 'i18n';
import React, { ComponentProps, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import style from './PB.module.scss';

const PBList = () => {
    const navigate = useNavigate();
    const { getTextTranslated } = useLang([NAMESPACE.PB]);
    const { companyNumber, companyId } = useParams();
    const { loading, deleteField, getFields } = usePB();
    const { setShowToast } = useContext(ApplicationContext);
    const [fields, setFields] = useState<PBField[]>([]);
    const [count, setCount] = useState(0);
    const LanguageMap = {
        [Language.ENGLISH]: 'English',
        [Language.SPANISH]: 'Spanish'
    };
    useEffect(() => {
        fetchFields();
    }, [companyNumber]);

    const fetchFields = async () => {
        try {
            const fields = await getFields(companyId);
            setFields(fields);
            setCount(Object.keys(fields).length);
        } catch (error) {
            setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: getTextTranslated('Failed to load fields', [NAMESPACE.PB]) });
        }
    };
    useEffect(() => {
        fetchFields();
    }, []);

    const onDeleteAttribute = async (companyId, field) => {
        if (companyId) {
            const deleteResponse = await deleteField(companyId, field);
            if (deleteResponse) {
                const updatedFields = await getFields(companyId);
                setFields(updatedFields);
                setShowToast({ toastMessage: getTextTranslated('Field Deleted Successfully', [NAMESPACE.PB]) });
            } else {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: getTextTranslated('Failed to delete', [NAMESPACE.PB]) });
            }
        }
    };
    const columns: ComponentProps<typeof DriscollsDataTable>['columns'] = [
        {
            id: 'pb-actions',
            size: 100,
            cell: ({ row }) => {
                return (
                    <>
                        <DriscollsEditButton
                            onClick={() =>
                                navigate(`${routes[routesName.PB_FIELDS].getRoute(row.original.Id, count)}`, {
                                    // Navigate to the edit route by setting params in to state until we move this logic to BE API
                                    state: { fields: row.original }
                                })
                            }
                        />
                        <DriscollsTrashButton onClick={() => onDeleteAttribute(companyId, row.original)} />
                    </>
                );
            }
        },
        {
            header: () => getTextTranslated('Field Name', NAMESPACE.PB),
            accessorKey: 'Attribute'
        },
        {
            header: () => getTextTranslated('Data Type', NAMESPACE.PB),
            size: 120,
            accessorKey: 'DataType'
        },
        {
            header: () => getTextTranslated('Language', NAMESPACE.PB),
            size: 120,
            accessorKey: 'Language',
            cell: ({ row }) => {
                return <>{LanguageMap[row.original.Language]}</>;
            }
        },
        {
            accessorKey: 'Selected',
            size: 80,
            header: () => getTextTranslated('Selected', NAMESPACE.PB),
            cell: ({ row }) => <>{row.original.Selected ? <FaIcons.FaCheck color="green" /> : <FaIcons.FaXmark color="red" />}</>
        }
    ];

    return (
        <DriscollsVContainer className={style.pbList} noPadding>
            <DriscollsHContainer className={style.pbActions} noPadding>
                <DriscollsButton onClick={() => navigate(`${routes[routesName.PB_FIELDS].getRoute('', count)}`)}>
                    <DriscollsTranslatedValue value={'New Field'} nameSpacing={NAMESPACE.PB} />
                </DriscollsButton>
            </DriscollsHContainer>
            <DriscollsComponentSwitcher
                direction={DirectionEnum.width}
                componentList={[
                    {
                        breakPoint: 799,
                        component: (
                            <DriscollsMobileList
                                isLoading={loading}
                                data={fields}
                                selectedPage={1}
                                onPageChange={() => console.log('page changed')}
                                itemsPerPage={1}
                                totalItems={10}
                                paginationText={' '}
                                hideKeys={['id', 'Personas']}
                                actionButtonRenderer={(row) => {
                                    return (
                                        <>
                                            <DriscollsEditButton
                                                onClick={() =>
                                                    navigate(`${routes[routesName.PB_FIELDS].getRoute(row.Id, count)}`, {
                                                        // Navigate to the edit route by setting params in to state until we move this logic to BE API
                                                        state: { fields: row.original }
                                                    })
                                                }
                                            />
                                            <DriscollsTrashButton onClick={() => onDeleteAttribute(companyId, row.original)} />
                                        </>
                                    );
                                }}
                            />
                        )
                    },

                    {
                        breakPoint: 800,
                        component: (
                            <DriscollsDataTable
                                className={style.pbTable}
                                isLoading={loading}
                                hideFilters
                                columns={columns}
                                data={fields}
                                hidePagination
                            />
                        )
                    }
                ]}
            />
        </DriscollsVContainer>
    );
};

export default PBList;
