import { ApplicationPage, ApplicationPageTitle, DriscollsLegend, DriscollsVContainer, FaIcons } from '@driscollsinc/style-guide-web2.0';
import ApplicationContextV2 from 'context/ApplicationContextV2';
import { NAMESPACE } from 'i18n';
import React, { useContext } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import style from './PB.module.scss';

const PBHome = () => {
    const { companyNumber } = useParams();
    const { companyEntities } = useContext(ApplicationContextV2);
    return (
        <ApplicationPage>
            <ApplicationPageTitle
                title={{ text: 'Pallet Builder Manage Fields', nameSpace: NAMESPACE.PB }}
                description={{
                    text: 'Here you can manage the extra fields that are going to be used in Pallet Builder feature inside OneDriscoll’s',
                    nameSpace: NAMESPACE.PB
                }}
            />
            <DriscollsVContainer className={style.pb}>
                <DriscollsLegend className={style.pbCompany} show title={<></>}>
                    <FaIcons.FaBuilding fontSize={24} />
                    {companyEntities.available[companyNumber].entity.label}
                </DriscollsLegend>
                <Outlet />
            </DriscollsVContainer>
        </ApplicationPage>
    );
};

export default PBHome;
