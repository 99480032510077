import { ManagePersona } from '@driscollsinc/users-management-module-ui';
import React from 'react';

const ManagePersonas = () => {
    return (
        <ManagePersona
            availablePersonas={[
                { label: 'Grower', value: 'grower' },
                { label: 'Super Admin', value: 'super_admin' }
            ]}
        />
    );
};

export default ManagePersonas;
