import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import ProtectedRoute from 'components/shared/protected/ProtectedRoute';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import Announcement from './Announcement';
import CreateAnnouncement from './CreateAnnouncement';
import ViewAnnouncement from './ViewAnnouncement';

const AnnouncementRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <ProtectedRoute>
                        <Announcement />
                    </ProtectedRoute>
                }
            />
            <Route
                path={routes[routesName.CREATE_ANNOUNCEMENT].path}
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect
                            personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]}
                            featureName={FEATURES.ANNOUNCEMENT_CREATE}
                            access="Write"
                        >
                            <CreateAnnouncement />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
            <Route
                path={routes[routesName.VIEW_ANNOUNCEMENT].path}
                element={
                    <ProtectedRoute>
                        <ViewAnnouncement />
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default AnnouncementRoutes;
