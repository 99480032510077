import { urls } from 'constants/urls';
import APIEndPoints from 'services/api';

export const GET_ALL_USERS = () => {
    return `${APIEndPoints.BASE()}${urls.USERS}/${urls.MANAGE}`;
};

export const DELETE_USER = (userEmail: string) => {
    return `${APIEndPoints.AWS_USER_MANAGEMENT_API()}${urls.USER}?email=${userEmail}`;
};
export const DELETE_USER_PERSONA = (userId: string, personaId: string, userEmail: string) => {
    return `${APIEndPoints.AWS_USER_MANAGEMENT_API()}${urls.USER}/${userId}/${urls.PERSONA}/${personaId}?email=${userEmail}`;
};

export const GET_PERSONA_CONFIG = (personaId: string) => {
    return `${APIEndPoints.BASE()}personas/persona/${personaId}`;
};

export const GET_USERS_PERSONA = (userId: string, personaId: string, userEmail: string) => {
    return `${APIEndPoints.AWS_USER_MANAGEMENT_API()}${urls.USER}/${userId}/${urls.PERSONA}/${personaId}?email=${userEmail}`;
};

export const INVITE_USER = () => {
    return `${APIEndPoints.AWS_USER_MANAGEMENT_API()}${urls.USER}/${urls.INVITE}`;
};

///user/{userId}/persona/{personaId}
export const UPDATE_USER_ASSIGNMENT_REPORTS = (userId: string, personaId: string, userEmail: string) => {
    return `${APIEndPoints.AWS_USER_MANAGEMENT_API()}user/${userId}/persona/${personaId}?email=${userEmail}`;
};

// TOD: Probably deprecated for v2
export const UPDATE_USER = () => {
    return `${APIEndPoints.AWS_USER_MANAGEMENT_API()}${urls.USER}/${urls.INVITE}`;
};

export const ADD_PERSONA = (userId: string, personaId: string, userEmail: string) => {
    return `${APIEndPoints.AWS_USER_MANAGEMENT_API()}user/${userId}/persona/${personaId}?email=${userEmail}`;
};
