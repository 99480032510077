import { DriscollsRichText } from '@driscollsinc/style-guide-web2.0';
import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ApplicationContext from 'context/ApplicationContext';
import { default as EventFormContext } from 'context/EventFormContext';
import { addMinutes } from 'date-fns';
import dayjs from 'dayjs';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect, useState } from 'react';
import DuDateUtilities from 'utils/DuDateUtilities';
import style from './EventFieldsFormControl.module.scss';

const EventFieldsFormControl = (props) => {
    const { initData, formData, memo, onChange, hideDates } = props;
    const newState = formData;
    const eventStartDateTime = initData?.StartDate || DuDateUtilities.RoundOffTime(30, new Date());
    const eventEndDateTime = initData?.EndDate || addMinutes(eventStartDateTime, 30);
    const { districtOptions: districts } = useContext(ApplicationContext);
    const { register, setValue, validate } = useContext(EventFormContext);
    const [isSelectDistrictOpen, setIsSelectDistrictOpen] = useState(false);
    const { getTextTranslated } = useLang();

    useEffect(() => {
        register('Description');
        register('StartDate');
        register('EndDate');
        register('DistrictCode');
    }, [register]);

    useEffect(() => {
        onChange({
            Name: '',
            Description: '',
            DistrictCode: [],
            StartDate: new Date(eventStartDateTime),
            EndDate: new Date(eventEndDateTime)
        });
        setValue('StartDate', eventStartDateTime);
        setValue('EndDate', eventEndDateTime);
    }, []);

    useEffect(() => {
        if (initData)
            onChange({
                Name: initData.Name,
                Description: initData.Description,
                StartDate: initData?.StartDate ? initData.StartDate : '',
                EndDate: initData?.EndDate ? initData.EndDate : '',
                DistrictCode: initData?.DistrictCode ? initData.DistrictCode.split(',') : []
            });
    }, [initData]);

    const onChangeHandler = (field: string, value: string | dayjs.Dayjs | string[]) => {
        if (field === 'StartDate' || field === 'EndDate') {
            const formattedDate = new Date(value.toString());
            newState[field] = formattedDate;
            setValue(field, formattedDate);
            validate('StartDate');
            validate('EndDate');
        } else {
            newState[field] = value;
            setValue(field, value);
        }
        setIsSelectDistrictOpen(false);
        onChange(newState);
    };

    return (
        <FormControl>
            <TextField
                defaultValue={initData?.Name}
                onChange={(event) => {
                    onChangeHandler('Name', event?.target?.value);
                }}
                label={getTextTranslated('Event Title', NAMESPACE.EVENTS)}
                placeholder={getTextTranslated('Enter Event Title', NAMESPACE.EVENTS)}
                className={style.title}
                inputProps={{ ...memo.register('Name') }}
                helperText={memo.errors.Name ? memo.errors.Name?.message : ''}
                error={!!memo.errors.Name}
            />
            <FormControl className={style.selectDistrict}>
                <InputLabel id="event-label">{getTextTranslated('Select District', NAMESPACE.SUPPORT)}</InputLabel>
                <Select
                    open={isSelectDistrictOpen}
                    onOpen={() => setIsSelectDistrictOpen(true)}
                    label={getTextTranslated('Select District', NAMESPACE.SUPPORT)}
                    labelId="event-label"
                    value={newState['DistrictCode'] || []}
                    onClose={() => setIsSelectDistrictOpen(false)}
                    onChange={(e) => {
                        onChangeHandler('DistrictCode', e.target.value);
                    }}
                    multiple
                    renderValue={(selected) => selected.map((itm) => itm).join(', ')}
                    inputProps={{ ...memo.register('DistrictCode') }}
                    error={!!memo.errors.DistrictCode}
                >
                    {(districts || []).map((item, index) => {
                        return (
                            <MenuItem value={item.DistrictCode} key={index}>
                                <Checkbox checked={newState.DistrictCode && newState.DistrictCode.indexOf(item.DistrictCode) > -1} />
                                {item.label}
                            </MenuItem>
                        );
                    })}
                </Select>
                <FormHelperText className={style.error}>{memo.errors.DistrictCode ? memo.errors.DistrictCode?.message : ''}</FormHelperText>
            </FormControl>
            <DriscollsRichText
                defaultValue={initData?.Description}
                label={getTextTranslated('Enter Event Description', NAMESPACE.EVENTS)}
                getJsonValue={(value: string) => {
                    onChangeHandler('Description', value);
                }}
                error={memo.errors.Description}
                onChange={() => null}
                editorState={null}
            />

            {!hideDates && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div className={style.dateRange}>
                        <DateTimePicker
                            label={getTextTranslated('Start Date Time', NAMESPACE.EVENTS)}
                            defaultValue={dayjs(eventStartDateTime)}
                            onChange={(newValue) => onChangeHandler('StartDate', newValue)}
                            disablePast
                        />

                        <DateTimePicker
                            label={getTextTranslated('End Date Time', NAMESPACE.EVENTS)}
                            defaultValue={dayjs(eventEndDateTime)}
                            onChange={(newValue) => onChangeHandler('EndDate', newValue)}
                            disablePast
                            slotProps={{
                                textField: {
                                    error: memo.errors.EndDate ? true : false,
                                    helperText: memo.errors.EndDate ? memo.errors.EndDate.message : ''
                                }
                            }}
                        />
                    </div>
                </LocalizationProvider>
            )}
        </FormControl>
    );
};

export default EventFieldsFormControl;
