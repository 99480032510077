import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withOktaAuth } from '@okta/okta-react';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import PreviewEventModal, { ICalendarEventPreview } from 'components/events/PreviewEventModal';
import Loader from 'components/shared/Loader/Loader';
import { TypographyTranslated } from 'components/shared/Typography/TypographyTranslated';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import { enUS, es, fr } from 'date-fns/locale';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import useFetchEvents from 'hooks/useFetchEvents';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { Children, useCallback, useState } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { viewsPerRoute } from 'routes/routesEnum';
import { getRouteByView } from 'routes/routesUtils';
import DuDateUtilities from 'utils/DuDateUtilities';
import DrcContainer from '../../layouts/dashboard/DrcContainer';
import { convertEventsListToCalendarFormat } from '../../utils/helper';
import style from './ViewAllEvents.module.scss';

const locales = {
    'en-US': enUS,
    es: es,
    fr: fr
};
const localizer = dateFnsLocalizer({ format, parse, startOfWeek, getDay, locales });

//styling the react-big-calendar
const StyledCalendar = styled(Calendar)(() => ({
    ['.rbc-month-view']: {
        padding: '10px'
    },
    ['.rbc-event']: {
        background: '#afd2af',
        color: '#555555'
    },
    ['.rbc-overlay']: {
        background: '#afd2af',
        '&.rbc-event': {
            background: '#afd2af',
            color: '#555555'
        }
    }
}));

const ViewEvents = (props) => {
    const { firstDate, lastDate } = DuDateUtilities.getFirstAndLastDayOfMonth(new Date());
    const [displayPopup, setDisplayPopup] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<ICalendarEventPreview | null>(null);
    const { oktaAuth } = props;
    const { data: events, loading: loadingEvent, error: errorEvents, fetchEvents: fetchEvents } = useFetchEvents(oktaAuth, null, firstDate, lastDate);
    const { getCurrentLanguageSelected, getTextTranslated } = useLang();

    const eventInCalenderFormat = convertEventsListToCalendarFormat(events);

    const handleSelected = (e) => {
        setSelectedEvent({ Id: e.id, Name: e.title, Description: e.description });
        setDisplayPopup(true);
    };

    const handleClose = () => {
        setDisplayPopup(!displayPopup);
    };

    const ColoredEventWrapper = ({ children }) =>
        React.cloneElement(Children.only(children), {
            style: {
                ...children?.props?.style,
                backgroundColor: '#afd2af',
                color: '#555555',
                border: '1px solid #9e9e9ef2'
            }
        });

    const onRangeChange = useCallback((range) => {
        const { startDate, endDate } = DuDateUtilities.getStartAndEndDatesFromRange(range);
        fetchEvents(startDate, endDate);
    }, []);

    const messages = {
        agenda: getTextTranslated('Agenda', NAMESPACE.EVENTS),
        allDay: getTextTranslated('All Day', NAMESPACE.EVENTS),
        month: getTextTranslated('Month', NAMESPACE.EVENTS),
        day: getTextTranslated('Day', NAMESPACE.EVENTS),
        today: getTextTranslated('Today', NAMESPACE.EVENTS),
        previous: getTextTranslated('Previous', NAMESPACE.EVENTS),
        next: getTextTranslated('Next', NAMESPACE.EVENTS),
        date: getTextTranslated('Date', NAMESPACE.EVENTS),
        noEventsInRange: getTextTranslated('There are no events in this range.', NAMESPACE.EVENTS),
        time: getTextTranslated('Time', NAMESPACE.EVENTS),
        tomorrow: getTextTranslated('Tomorrow', NAMESPACE.EVENTS),
        week: getTextTranslated('Week', NAMESPACE.EVENTS),
        work_week: getTextTranslated('Work Week', NAMESPACE.EVENTS),
        yesterday: getTextTranslated('Yesterday', NAMESPACE.EVENTS)
    };

    return (
        <DrcContainer heading="Events" style={{ minHeight: '100vh' }}>
            {loadingEvent && <Loader />}
            {errorEvents && <p>Can not load the events</p>}
            {!loadingEvent && !errorEvents && events && (
                <section className={style.CalendarContainer}>
                    <PersonaBaseProtect
                        personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]}
                        featureName={FEATURES.DASHBOARD_EVENTS}
                        access="Write"
                    >
                        <div className={style.actions}>
                            <Button variant="contained" href={getRouteByView(viewsPerRoute.EVENT_CREATE)}>
                                <AddIcon />
                                <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{'Create'}</TypographyTranslated>
                            </Button>
                        </div>
                    </PersonaBaseProtect>
                    <StyledCalendar
                        onRangeChange={onRangeChange}
                        culture={getCurrentLanguageSelected()}
                        className={style.calendarContent}
                        localizer={localizer}
                        events={eventInCalenderFormat}
                        selectable={true}
                        onSelectEvent={handleSelected}
                        components={{
                            eventWrapper: ColoredEventWrapper
                        }}
                        messages={messages}
                    />
                </section>
            )}
            <PreviewEventModal open={displayPopup} onClose={handleClose} event={selectedEvent} />
        </DrcContainer>
    );
};

export default withOktaAuth(ViewEvents);
