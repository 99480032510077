import { withOktaAuth } from '@okta/okta-react';
import ReportGridContainer from 'components/report/ReportGridContainer';
import ApplicationContext from 'context/ApplicationContext';
import useGetSupplementaryReports from 'hooks/useGetSupplementaryReports';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import DrcContainer from 'layouts/dashboard/DrcContainer';
import React, { useContext, useEffect, useState } from 'react';
import { getAppliedFilters } from 'utils/helper';
import { ReportType } from './ReportTypes';
const FRESH_FRUIT_RECEIVING_REPORT = 'freshfruitreceivingreport';
const FreshFruitReceivingReport = (props) => {
    const { oktaAuth } = props;
    const { getTextTranslated } = useLang();
    const { reportsList, pageCount, totalReports, fetchWithFilters, loading, fetch } = useGetSupplementaryReports(oktaAuth);
    const { filters } = useContext(ApplicationContext);
    const [page, setPage] = useState(1);
    const handlePageChange = (event, value) => {
        setPage(value);
        fetchWithFilters(getAppliedFilters(filters), value, FRESH_FRUIT_RECEIVING_REPORT);
    };
    useEffect(() => {
        fetch('', 1, FRESH_FRUIT_RECEIVING_REPORT);
    }, []);

    return (
        <DrcContainer heading={getTextTranslated('Fresh Fruit Receiving Report', NAMESPACE.REPORT)}>
            <ReportGridContainer
                handlePageChange={handlePageChange}
                page={page}
                totalUsers={totalReports}
                loading={loading}
                reportList={reportsList}
                executionTime={undefined}
                pageCount={pageCount}
                reportType={ReportType.FreshFruitReceivingReport}
            />
        </DrcContainer>
    );
};

export default withOktaAuth(FreshFruitReceivingReport);
