export enum ReportType {
    DailyWeekly = 'Daily Weekly',
    GrowerSettlement = 'Grower Settlement',
    SupplementalStatementReport = 'Supplemental StatementReport',
    CanadaManualTax = 'Canada Manual Tax',
    FreshFruitReceivingReport = 'Fresh Fruit Receiving Report'
}

export type TReportType =
    | ReportType.DailyWeekly
    | ReportType.GrowerSettlement
    | ReportType.SupplementalStatementReport
    | ReportType.CanadaManualTax
    | ReportType.FreshFruitReceivingReport;
