import { urls } from 'constants/urls';
import APIEndPoints from 'services/api';

export const GET_ALL_AVAILABLE_FEATURES = (pageNumber) => {
    return `${APIEndPoints.BASE()}${urls.FEATURES}?pageNumber=${pageNumber}`;
};

export const POST_FEATURES = () => {
    return `${APIEndPoints.BASE()}${urls.FEATURES}`;
};

export const GET_SELECTED_FEATURE_DETAILS = (id) => {
    return `${APIEndPoints.BASE()}${urls.FEATURES}?featureid=${id}`;
};
