const useUtilities = () => {
    const debounce = (fn, delay = 400) => {
        let timeout;

        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                fn(...args);
            }, delay);
        };
    };

    return { debounce };
};

export default useUtilities;
