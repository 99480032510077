import { urls } from 'constants/urls';
import APIEndPoints from 'services/api';

export const GET_PB_FIELDS = (companyId: string) => {
    return `${APIEndPoints.BASE()}${urls.ATTRIBUTE_LABELS}?companyid=${companyId}`;
};

export const UPDATE_PB_FIELDS = () => {
    return `${APIEndPoints.BASE()}${urls.ATTRIBUTE_LABELS}`;
};
