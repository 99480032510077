import {
    DriscollsAppLayout,
    DriscollsMobileNavigation,
    DriscollsTranslatedValue,
    DriscollsTypography,
    DriscollsTypographyEnum,
    DriscollsTypographyStyles,
    DriscollsTypographyTranslated
} from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import ApplicationContextV2 from 'context/ApplicationContextV2';
import useCheckUserToken from 'hooks/useCheckUserToken';
import useConfig from 'hooks/v2/useConfig';
import { NAMESPACE } from 'i18n';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import logo from '../../../Images/Logo_Small_Transparent.png';
import AccountPopover from '../header/AccountPopover';
import LanguagePopover from '../header/LanguagePopover';
import PersonaFeaturePopover from '../header/PersonaFeaturePopover';
import styles from './ApplicationLayout.module.scss';

const DriscollsLogo = () => <img src={logo} alt="driscolls-logo" className={styles.bannerLogo} />;

const ApplicationLayout = () => {
    const isLoggedIn = useCheckUserToken();

    const { userSetup } = useContext(ApplicationContextV2);
    const { setUserPersona, isMasterDataInitialized } = useContext(ApplicationContext);
    const { getNavigationByConfig } = useConfig();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isMasterDataInitialized) {
            navigate(routes[routesName.INITIALIZE_APPLICATION].getRoute());
        }
    }, []);

    return isLoggedIn ? (
        <DriscollsAppLayout
            applicationTitle={
                <DriscollsTypographyTranslated className={styles.capitalized} type={DriscollsTypographyEnum.H6} typographyStyle={DriscollsTypographyStyles.SUBTITLE1}>
                    Grower Engagement Platform
                </DriscollsTypographyTranslated>
            }
            header={{
                headerClassName: styles.headerContainer,
                drawer: (close: () => void) => {
                    return (
                        <>
                            <DriscollsLogo />
                            <DriscollsMobileNavigation navigationItem={getNavigationByConfig(userSetup)} drawerClose={close} />
                            <DriscollsTypography
                                type={DriscollsTypographyEnum.P}
                                typographyStyle={DriscollsTypographyStyles.SUBTITLE5}
                                className={styles.appVersion}
                            >
                                <DriscollsTranslatedValue value="App Version" nameSpace={NAMESPACE.COMMON} />[{process.env.REACT_APP_VERSION}]
                            </DriscollsTypography>
                        </>
                    );
                },
                drawerDirection: 'left',
                tools: [
                    <>
                        {window.config.uiConfig.v2EnablePersonaFeatureManagement === 'true' && <PersonaFeaturePopover />}
                        <LanguagePopover />
                        <AccountPopover setPersona={setUserPersona} />
                    </>
                ],
                leftChildren: [
                    <>
                        <DriscollsLogo />
                    </>
                ]
            }}
            navigation={{
                navigationItem: getNavigationByConfig(userSetup)
            }}
        />
    ) : null;
};

export default ApplicationLayout;
