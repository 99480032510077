import { i18next } from '@driscollsinc/style-guide-web2.0';
import AppProvider from 'context/AppProvider';
import AppProviderV2 from 'context/AppProviderV2';
import useLang, { Lang } from 'hooks/useLang';
import 'i18n';
import React, { useEffect } from 'react';
import { defaultLanguageHandler } from 'utils/languageHelper';
import App from './App';

const Application = () => {
    const { changeLanguage, getCurrentLanguageSelected } = useLang();
    const lang = getCurrentLanguageSelected();

    useEffect(() => {
        // init language
        const defaultLanguageCode = defaultLanguageHandler() as Lang;
        changeLanguage(defaultLanguageCode);
    }, []);

    useEffect(() => {
        i18next.changeLanguage(lang);
    }, [lang]);

    return (
        <AppProviderV2>
            <AppProvider>
                <App />
            </AppProvider>
        </AppProviderV2>
    );
};
export default Application;
