import { CreatePersona, TPersonaDetailsType } from '@driscollsinc/users-management-module-ui';
import { useOktaAuth } from '@okta/okta-react';
import usePersonaDetails from 'hooks/v2/usePersonaDetails';
import React, { useEffect, useState } from 'react';

const AddPersona = () => {
    const { oktaAuth } = useOktaAuth();

    const { fetchPersonaDetails, personaDetails } = usePersonaDetails(oktaAuth);
    const [personaId, setPersonaId] = useState('');
    useEffect(() => {
        fetchPersonaDetails(personaId);
    }, [personaId]);

    const getPersonaDetails = async (id) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (!id) reject(Error('Persona does not exist'));
                setPersonaId(id);
                resolve(personaDetails satisfies TPersonaDetailsType);
            }, 2000);
        });
    };

    return <CreatePersona getPersonaDetails={getPersonaDetails} />;
};

export default AddPersona;
