import { Call } from '@driscollsinc/driscolls-display-rules';
import { ENTITY, TUIPersona, TUIPersonaInfo, TUIUser } from '@driscollsinc/users-management-module-ui';
import { ApplicationConstants } from 'constants/v2/ApplicationConstants';
import useGetApiTokens from 'hooks/useGetApiTokens';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import { useState } from 'react';
import {
    ADD_PERSONA,
    DELETE_USER,
    DELETE_USER_PERSONA,
    GET_ALL_USERS,
    GET_USERS_PERSONA,
    INVITE_USER,
    UPDATE_USER,
    UPDATE_USER_ASSIGNMENT_REPORTS
} from 'services/api/v2/UserManagementAPI';
import {
    DeleteResponse,
    InviteUserPayload,
    ManageUserPersona,
    ManageUserReqPayload,
    ManageUserResponse,
    TUsersPersona,
    UpdateUserPayload,
    UserPersonaDetails,
    UserResponse
} from 'types/v2/userManagement';
import { convertArrayToCommaSeparatedString } from 'utils/helper';

const useUserManagement = (oktaAuth: any) => {
    const [loading, setLoading] = useState(true);
    const { getTokenAndId } = useGetApiTokens();
    const [error, setError] = useState(null);
    const [users, setUsers] = useState([]);
    const [deleteUserResponse, setDeleteUserResponse] = useState<DeleteResponse | object>({});
    const [totalNofOfUsers, setTotalNofOfUsers] = useState(1);
    const [deletePersonaResponse, setDeletePersonaResponse] = useState<DeleteResponse | object>({});
    const [inviteUserResponse, setInviteUserResponse] = useState<UserResponse>(null);
    const [updateUserResponse, setUpdateUserResponse] = useState<UserResponse>(null);
    const [userPersonaTypes, setUserPersonaTypes] = useState<Array<TUIPersona> | []>([]);
    const { getTextTranslated } = useLang();

    const mapPersonas = (personas?: Array<ManageUserPersona>) => {
        const ret: Array<TUIPersonaInfo> = [];
        if (personas && personas.length > 0) {
            personas.map((persona, index) => {
                const mapped: TUIPersonaInfo = {
                    id: persona.PersonaId,
                    name: persona.Persona,
                    displayName: getTextTranslated(persona.Persona, NAMESPACE.COMMON),
                    cognitoGroup: persona.CognitoGroup,
                    districts: [],
                    companies: [],
                    ranches: []
                };
                if (persona.Districts) {
                    persona.Districts.map((district) => {
                        mapped.districts.push({ entityType: ENTITY.DISTRICT, label: district.DistrictId, value: district.DistrictId });
                    });
                }
                if (persona.Companies) {
                    persona.Companies.map((company) => {
                        mapped.companies.push({ entityType: ENTITY.COMPANY, label: company.CompanyNbr, value: company.CompanyNbr });
                    });
                }
                if (persona.Ranches) {
                    persona.Ranches.map((ranch) => {
                        mapped.ranches.push({ entityType: ENTITY.RANCH, label: ranch.RanchNbr, value: ranch.RanchNbr });
                    });
                }
                ret.push(mapped);
            });
        }
        return ret;
    };
    const getAllUsers = async (manageUserReqPayload: ManageUserReqPayload) => {
        setLoading(true);
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: GET_ALL_USERS(),
                    name: 'ManageUser',
                    method: 'POST',
                    options: {},
                    data: {
                        UserOktaId: userOktaId,
                        ...manageUserReqPayload
                    }
                },
                token
            );
            if (response.display) {
                setTotalNofOfUsers(response.display.TotalUsers);
                const mappedData: Array<TUIUser> = [];
                Object.values(response.raw.data.Response).map((value: ManageUserResponse) => {
                    let userType = 'External';
                    let email = value.Email;

                    if (email) {
                        const atIndex = email.indexOf('@');
                        if (atIndex !== -1) {
                            const domain = email.slice(atIndex + 1);
                            userType = domain === 'driscolls.com' ? 'Internal' : 'External';
                        } else {
                            //where email is in unexpected format like "Email - 5610"
                            email = '';
                        }
                    }
                    const user: TUIUser = {
                        id: value.UserOktaId,
                        FirstName: value.FirstName,
                        LastName: value.LastName,
                        Lang: value.Lang,
                        Email: value.Email,
                        Phone: value.Phone,
                        UserType: userType,
                        Personas: []
                    };
                    user.Personas = mapPersonas(value.Personas);
                    mappedData.push(user);
                });
                setUsers(mappedData);
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const exportUsers = async (manageUserReqPayload: ManageUserReqPayload) => {
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: GET_ALL_USERS(),
                    name: 'ManageUser',
                    method: 'POST',
                    options: {},
                    data: {
                        UserOktaId: userOktaId,
                        ...manageUserReqPayload
                    }
                },
                token
            );
            if (response.display) {
                setTotalNofOfUsers(response.display.TotalUsers);
                const mappedData: Array<TUIUser> = [];
                Object.values(response.raw.data.Response).map((value: ManageUserResponse) => {
                    const user: TUIUser = {
                        FirstName: value.FirstName,
                        LastName: value.LastName,
                        Lang: value.Lang,
                        Email: value.Email,
                        Phone: value.Phone,
                        Personas: convertArrayToCommaSeparatedString(value.Personas, 'Persona')
                    };
                    mappedData.push(user);
                });
                return mappedData;
            }
        } catch (error) {
            setError(error);
        }
    };

    const getUserDetailsWithPersonas = async (userId, reqPersonaId, userEmail) => {
        setLoading(true);
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        const requestPayload = {
            AuthIdFilter: userId,
            UserOktaId: userOktaId,
            GroupByUser: true,
            RequestorPersonaId: reqPersonaId
        };
        try {
            const response = await Call(
                {
                    url: GET_USERS_PERSONA(userId, reqPersonaId, userEmail),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (response.display) {
                let userProfilePersonaDetails: TUsersPersona = {
                    PersonalInfo: null,
                    PersonaType: []
                };

                userProfilePersonaDetails = {
                    PersonalInfo: response.display.PersonalInfo,
                    PersonaType: response.display.PersonaType
                };
                return userProfilePersonaDetails;
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const inviteUser = async (inviteUser: InviteUserPayload) => {
        setLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: INVITE_USER(),
                    method: 'POST',
                    options: {},
                    data: inviteUser
                },
                token
            );
            if (response.display) {
                setInviteUserResponse(response.display);
                return response.display;
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const getUsersPersona = async (userId: string, personaId: string, userEmail: string) => {
        setLoading(true);
        const { token, userOktaId } = await getTokenAndId(oktaAuth);

        try {
            const response = await Call(
                {
                    url: GET_USERS_PERSONA(userId, personaId, userEmail),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (response.display) {
                const userPersonaDetails: UserPersonaDetails = {
                    PersonalInfo: response.display.PersonalInfo,
                    PersonaType: response.display.PersonaType,
                    Assignments: response.display.Assignments,
                    Reports: response.display.Reports
                };
                return userPersonaDetails;
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const deleteUser = async (userId, userEmail) => {
        setLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        const requestPayload = {
            Status: 0,
            UserOktaId: userId,
            UpdatedBy: ''
        };
        try {
            const response = await Call(
                {
                    url: DELETE_USER(userEmail),
                    method: 'DELETE',
                    options: {},
                    data: requestPayload
                },
                token
            );
            if (response.display) {
                setDeleteUserResponse(response.display);
                return response.display;
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const deletePersonaFromUser = async (userId, persona, userType, userEmail) => {
        setLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        const requestPayload = {
            PersonaId: persona.id,
            BusinessUnit: 'DOTA',
            AppName: ApplicationConstants.APPLICATION_NAME,
            CognitoPersonaName: userType === 'Internal' ? '' : persona.cognitoGroup,
            Status: 0,
            UserOktaId: userId,
            UpdatedBy: '',
            UpdatedAt: ''
        };
        console.log('deletePersonaFromUser requestPayload', requestPayload);
        try {
            const response = await Call(
                {
                    url: DELETE_USER_PERSONA(userId, persona.id, userEmail),
                    method: 'DELETE',
                    options: {},
                    data: requestPayload
                },
                token
            );
            if (response.display) {
                setDeletePersonaResponse(response.display);
                return response.display;
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const updateUserAssignmentsReports = async (updateUserPayload: InviteUserPayload, userId: string, personaId: string, userEmail: string) => {
        // UPDATE_USER_ASSIGNMENT_REPORTS
        setLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: UPDATE_USER_ASSIGNMENT_REPORTS(userId, personaId, userEmail),
                    method: 'PUT',
                    options: {},
                    data: updateUserPayload
                },
                token
            );
            if (response.display) {
                setUpdateUserResponse(response.display);
                return response.display;
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const updateUser = async (updateUserPayload: UpdateUserPayload) => {
        setLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: UPDATE_USER(),
                    method: 'PUT',
                    options: {},
                    data: updateUserPayload
                },
                token
            );
            if (response.display) {
                setUpdateUserResponse(response.display);
                return response.display;
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };
    const addPersona = async (addPersona, userId, personaId, userEmail) => {
        setLoading(true);
        const { token } = await getTokenAndId(oktaAuth);
        try {
            const response = await Call(
                {
                    url: ADD_PERSONA(userId, personaId, userEmail),
                    method: 'POST',
                    options: {},
                    data: addPersona
                },
                token
            );
            if (response.display) {
                return response.display;
            }
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };
    return {
        error,
        loading,
        getAllUsers,
        users,
        deleteUser,
        deleteUserResponse,
        totalNofOfUsers,
        getUsersPersona,
        deletePersonaFromUser,
        deletePersonaResponse,
        inviteUser,
        inviteUserResponse,
        exportUsers,
        getUserDetailsWithPersonas,
        userPersonaTypes,
        updateUserResponse,
        updateUser,
        updateUserAssignmentsReports,
        addPersona
    };
};

export default useUserManagement;
