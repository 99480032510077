export const EXPORT_USER_FILE_NAME = 'GEP - Users.xlsx';

export const EXPORT_USERS_COLUMNS = [
    { name: 'First Name', key: 'FirstName' },
    { name: 'Last Name', key: 'LastName' },
    { name: 'Language', key: 'Lang' },
    { name: 'Email', key: 'Email' },
    { name: 'Phone', key: 'Phone' },
    { name: 'Personas', key: 'Personas' }
];
