import { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { getAllPersonas } from 'utils/personasUtils';
import { ROUTES } from '../../constants/routes';
import { INavBarDataObj } from './NavigationBar.types';

/** @Object NavigationBarData
 * @Description
 *  description: general info about the role
 *  iconName: is the icon name used to pull the svg file and generate the icon
 *  isEnabled: boolean, permission whether the icon should be displayed in the nav bar
 *  title: name of the icon
 * */

export const NavigationBarData: INavBarDataObj[] = [
    {
        description: 'Various Information.',
        iconName: 'dashboard',
        isEnabled: window.config.dashboardEnabled === 'true',
        path: ROUTES.DASHBOARD,
        title: 'Dashboard',
        personasAllowed: [
            Persona.SUPER_ADMIN,
            Persona.REGIONAL_ADMIN,
            Persona.HARVEST_PLANNER,
            Persona.GROWER_ADMIN,
            Persona.QA_ADMIN,
            Persona.RANCH_PLANNER,
            Persona.PALLET_BUILDER,
            Persona.GROWER_USER,
            Persona.PORTAL_USER,
            Persona.QUALITY_VIEWER,
            Persona.REPORT_BUILDER
        ]
    },
    {
        title: 'User Management',
        description: 'Manage User including Invite, Edit and View with assigned Districts, Companies and Ranches.',
        path: ROUTES.USER_MANAGEMENT,
        iconName: 'user-management',
        isEnabled: window.config.userManagementEnabled === 'true',
        personasAllowed: [Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN, Persona.GROWER_ADMIN]
    },
    {
        title: 'PTI',
        description: '',
        path: ROUTES.PTI,
        iconName: 'pti',
        isEnabled: window.config.ptiEnabled === 'true',
        personasAllowed: [Persona.SUPER_ADMIN, Persona.PTI_APP, Persona.PTI_USER]
    },
    {
        title: 'Reports',
        description: '',
        path: ROUTES.REPORTS,
        iconName: 'reports',
        isEnabled: window.config.reportsEnabled === 'true',
        personasAllowed: [
            Persona.SUPER_ADMIN,
            Persona.REGIONAL_ADMIN,
            Persona.HARVEST_PLANNER,
            Persona.GROWER_ADMIN,
            Persona.QA_ADMIN,
            Persona.RANCH_PLANNER,
            Persona.PALLET_BUILDER,
            Persona.GROWER_USER,
            Persona.PORTAL_USER,
            Persona.QUALITY_VIEWER
        ]
    },
    {
        title: 'Announcement',
        description: 'Send and View the communication with Growers.',
        path: ROUTES.ANNOUNCEMENT,
        iconName: 'announcements',
        isEnabled: window.config.communicationEnabled === 'true',
        personasAllowed: [
            Persona.SUPER_ADMIN,
            Persona.REGIONAL_ADMIN,
            Persona.HARVEST_PLANNER,
            Persona.GROWER_ADMIN,
            Persona.QA_ADMIN,
            Persona.RANCH_PLANNER,
            Persona.PALLET_BUILDER,
            Persona.GROWER_USER
        ]
    },
    {
        title: 'Library',
        description: '',
        path: ROUTES.DOCUMENT_LIBRARY,
        iconName: 'library',
        isEnabled: window.config.libraryEnabled === 'true',
        personasAllowed: [
            Persona.SUPER_ADMIN,
            Persona.REGIONAL_ADMIN,
            Persona.HARVEST_PLANNER,
            Persona.GROWER_ADMIN,
            Persona.QA_ADMIN,
            Persona.RANCH_PLANNER,
            Persona.PALLET_BUILDER,
            Persona.GROWER_USER,
            Persona.PORTAL_USER
        ]
    },
    {
        title: 'Support',
        description: '',
        path: ROUTES.SUPPORT,
        iconName: 'support',
        isEnabled: window.config.supportEnabled === 'true',
        personasAllowed: [
            Persona.SUPER_ADMIN,
            Persona.REGIONAL_ADMIN,
            Persona.HARVEST_PLANNER,
            Persona.GROWER_ADMIN,
            Persona.QA_ADMIN,
            Persona.RANCH_PLANNER,
            Persona.PALLET_BUILDER,
            Persona.GROWER_USER
        ]
    },
    {
        title: 'Analytics',
        description: '',
        path: ROUTES.ANALYTICS,
        iconName: 'analytics',
        isEnabled: window.config.analyticsEnabled === 'true',
        personasAllowed: [Persona.SUPER_ADMIN]
    },
    {
        title: 'Weather',
        description: 'WeatherDescription',
        path: ROUTES.WEATHER,
        iconName: 'weather-overcast',
        isEnabled: window.config.weatherEnabled === 'true',
        personasAllowed: [Persona.SUPER_ADMIN, Persona.GEO_WEATHER]
    },
    {
        title: 'BerryConnect',
        description: 'BerryConnectDescription',
        path: ROUTES.BERRY_CONNECT,
        iconName: 'berry-connect',
        isEnabled: window.config.berryConnectEnabled === 'true',
        personasAllowed: getAllPersonas()
    }
];
