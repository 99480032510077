import { PersonaManagementEntryPoint } from '@driscollsinc/users-management-module-ui';
import { useOktaAuth } from '@okta/okta-react';
import usePersonaDetails from 'hooks/v2/usePersonaDetails';
import React, { useEffect, useState } from 'react';
const PersonaManagement = () => {
    const { oktaAuth } = useOktaAuth();

    const { getAvailablePersonas, personaData } = usePersonaDetails(oktaAuth);
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        getAvailablePersonas(currentPage.toString());
    }, [currentPage]);

    const handleChangePageNumber = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return <PersonaManagementEntryPoint data={personaData} onPageChange={handleChangePageNumber} selectedPage={1} />;
};

export default PersonaManagement;
