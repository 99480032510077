import { Call } from '@driscollsinc/driscolls-display-rules';
import { DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import { NAMESPACE } from 'i18n';
import { useContext, useState } from 'react';
import APIEndPoints from '../services/api';
import useGetApiTokens from './useGetApiTokens';
import useLang from './useLang';

/**
 * This hook fetches the list of grower settlement reports
 * {@link APIEndPoints.GET_GROWER_SETTLEMENT_REPORTS}
 *
 */
const SUPPLEMENTARY_REPORT = 'supplemental statement';
const CANADA_MANUAL_TAX_REPORT = 'canada tax';
const SERVER_UPLOAD_RESPONSE = 'Request processed successfully';
const FRESH_FRUIT_RECEIVING_REPORT = 'freshfruitreceivingreport';
const useGetSupplementaryReports = (oktaAuth: any) => {
    const { getTokenAndId } = useGetApiTokens();
    const [reportsList, setReportList] = useState([]);
    const [growerValidationResult, setGrowerValidationResult] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [totalReports, setTotalReports] = useState(0);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const { getTextTranslated } = useLang();
    const { setShowToast } = useContext(ApplicationContext);
    const ITEMS_PER_PAGE = 50;

    const fetch = async (filters = '', page = 1, type = SUPPLEMENTARY_REPORT) => {
        setReportList([]);
        setLoading(true);
        const { token, userOktaId } = await getTokenAndId(oktaAuth);
        const currentPage = page === 1 ? 0 : (page - 1) * ITEMS_PER_PAGE + 1;
        try {
            const data = await Call(
                {
                    name: 'GrowerSettlementReport',
                    url:
                        type === SUPPLEMENTARY_REPORT
                            ? APIEndPoints.GET_SUPPLEMENTARY_STATEMENT_REPORT(userOktaId, currentPage, ITEMS_PER_PAGE, filters)
                            : type === FRESH_FRUIT_RECEIVING_REPORT
                            ? APIEndPoints.GET_FRESH_FRUIT_RECEIVING_REPORT(userOktaId, currentPage, ITEMS_PER_PAGE, filters)
                            : APIEndPoints.GET_CANADA_MANUAL_TAX_REPORT(userOktaId, currentPage, ITEMS_PER_PAGE, filters),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Something Went Wrong!' });
                return console.log(data.errors[0]);
            }
            const results = data.display;
            const totalResults = results.TotalCount;
            setPageCount(Math.round(totalResults / ITEMS_PER_PAGE));
            setTotalReports(totalResults);
            if (results.Data) {
                setReportList(results.Data);
            } else {
                setReportList([]);
            }
        } catch (err) {
            setError(err);
            setShowToast({
                severity: DriscollsToastSeverity.ERROR,
                toastMessage: 'An error occurred while fetching reports. Please try again',
                nameSpacing: NAMESPACE.REPORT
            });
        } finally {
            setLoading(false);
        }
    };

    const validateGrowers = async (growers: any, loadingMessage: string) => {
        setLoading(true);
        setLoadingMessage(loadingMessage);
        try {
            const { token, userOktaId } = await getTokenAndId(oktaAuth);
            const response = await Call(
                {
                    url: APIEndPoints.POST_VALIDATE_GROWERS(),
                    method: 'POST',
                    options: {},
                    data: growers
                },
                token
            );
            setGrowerValidationResult(response.display);
            return response.display?.InvalidGrowers;
        } catch (err) {
            setError('Error');
        } finally {
            setLoading(false);
        }
    };

    const updateSupplementaryReport = async (payload: any, loadingMessage: string) => {
        setLoading(true);
        setLoadingMessage(loadingMessage);
        try {
            const { token } = await getTokenAndId(oktaAuth);
            const response = await Call(
                {
                    url: APIEndPoints.POST_SUPPLEMENTARY_REPORT(),
                    method: 'POST',
                    options: {},
                    data: payload
                },
                token
            );
            return response?.display?.Message === SERVER_UPLOAD_RESPONSE;
        } catch (err) {
            setError('Error');
        } finally {
            setLoading(false);
        }
    };

    const validateFiles = (fileList: any) => {
        let fileStatus = [];
        fileList.map((file) => {
            const fileName = file.name;
            fileStatus.push(validateFileName(fileName));
        });
        return fileStatus;
    };

    const validateFileName = (fileName) => {
        //0018076_Supplemental Statement_202335
        //0018076_Canada Tax_202335
        const errorMessage = [];
        const arrTypes = fileName.split('_');
        const growerNumber = arrTypes[0];
        const reportName = arrTypes[1];
        const poolweek = arrTypes[2].split('.pdf')[0];
        if (reportName.toLowerCase() !== SUPPLEMENTARY_REPORT && reportName.toLowerCase() !== CANADA_MANUAL_TAX_REPORT) {
            errorMessage.push(getTextTranslated('Invalid file name', NAMESPACE.REPORT));
        }
        if (isNaN(poolweek)) {
            errorMessage.push(getTextTranslated('Invalid poolweek format', NAMESPACE.REPORT));
        }
        if (isNaN(growerNumber)) {
            errorMessage.push(getTextTranslated('Invalid grower number', NAMESPACE.REPORT));
        }
        return {
            fileName: fileName,
            growerNumber: growerNumber,
            errorMessage: errorMessage && errorMessage.length > 0 ? errorMessage : null
        };
    };

    const fetchWithFilters = (filters: string, page: number, type: string = SUPPLEMENTARY_REPORT) => {
        fetch(filters, page, type);
    };

    return {
        fetch,
        loading,
        loadingMessage,
        reportsList,
        pageCount,
        totalReports,
        error,
        fetchWithFilters,
        validateFiles,
        validateGrowers,
        growerValidationResult,
        updateSupplementaryReport,
        validateFileName
    };
};
export default useGetSupplementaryReports;
