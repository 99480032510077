import { CreateFeature, TFeatureDetailsType } from '@driscollsinc/users-management-module-ui';
import { useOktaAuth } from '@okta/okta-react';
import useFeatureDetails from 'hooks/v2/useFeatureDetails';
import React from 'react';
import { CreateFeatureData, FeaturePayloadData } from 'types/v2/featuremanagement';
const AddFeature = () => {
    const { oktaAuth } = useOktaAuth();

    const { createFeature, fetchFeatureDetails, featureDetails } = useFeatureDetails(oktaAuth);

    const handleSubmit = (values: CreateFeatureData) => {
        if (values) {
            const payloadData: FeaturePayloadData = {
                FeatureName: values.featureName,
                Description: values.description,
                ApplicationId: '1',
                IsActive: true
            };
            createFeature(payloadData);
        }
    };

    const getFeatureDetailsById = async (featureId) => {
        const featureData: TFeatureDetailsType = await fetchFeatureDetails(featureId);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (!featureId) reject(Error('Feature does not exist'));
                else {
                    resolve({
                        featureName: featureData.featureName,
                        displayName: featureData.featureName,
                        description: featureData.description
                    } satisfies TFeatureDetailsType);
                }
            }, 2000);
        });
    };
    return <CreateFeature onSubmitHandler={handleSubmit} getFeatureDetailsById={getFeatureDetailsById} />;
};

export default AddFeature;
