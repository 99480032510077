import AddIcon from '@mui/icons-material/Add';
import { List } from '@mui/material';
import Divider from '@mui/material/Divider';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import { ROUTES } from 'constants/routes';
import { NAMESPACE } from 'i18n';
import React, { Fragment } from 'react';
import getRoutePath from 'routes/routesUtils';
import { TypographyTranslated } from '../../shared/Typography/TypographyTranslated';
import DLink from '../Link';
import { ICalendarEvent } from './CalendarTypes';
import Event from './Event';
import style from './RecentEvents.module.scss';

const RecentEvents = ({ events }: { events: Array<ICalendarEvent> | [] }) => {
    return (
        <div className={style.recentEventsContainer}>
            <span className={style.action}>
                <PersonaBaseProtect
                    personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]}
                    featureName={FEATURES.DASHBOARD_EVENTS}
                    access="Write"
                >
                    <DLink href={getRoutePath(ROUTES.EVENTSCREATE)} variant="overline">
                        <span className={style.button}>
                            <AddIcon />
                            <TypographyTranslated nameSpacing={NAMESPACE.EVENTS} variant="overline">
                                {'Events'}
                            </TypographyTranslated>
                        </span>
                    </DLink>
                </PersonaBaseProtect>
                <PersonaBaseProtect
                    personasAllowed={[Persona.GROWER_ADMIN, Persona.GROWER_USER, Persona.QUALITY_VIEWER, Persona.REPORT_BUILDER, Persona.PORTAL_USER]}
                    featureName={FEATURES.DASHBOARD_EVENTS}
                    access="Read"
                >
                    <TypographyTranslated nameSpacing={NAMESPACE.EVENTS} variant="overline">
                        {'Events'}
                    </TypographyTranslated>
                </PersonaBaseProtect>
            </span>
            <article className={style.eventsContainer}>
                <Divider />
                <List>
                    {(events || []).map((event) => {
                        return (
                            <Fragment key={event.EventId}>
                                <Event event={event} />
                                <Divider />
                            </Fragment>
                        );
                    })}
                </List>
            </article>
            <span className={`${style.action} ${style.all}`}>
                <DLink href={getRoutePath(ROUTES.EVENTSVIEWALL)}>
                    <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{'View All'}</TypographyTranslated>
                </DLink>
            </span>
        </div>
    );
};

export default RecentEvents;
