import { DriscollsToast, DriscollsToastPosition, DriscollsToastSeverity } from '@driscollsinc/style-guide-web2.0';
import ApplicationContext from 'context/ApplicationContext';
import { NAMESPACE } from 'i18n';
import React, { useContext } from 'react';
import { IShowToast } from 'types/application';

const GEPToast = () => {
    const { showToast, setShowToast } = useContext(ApplicationContext);

    const handleClose = () => {
        setShowToast({ toastMessage: '' } as IShowToast);
    };

    return (
        <DriscollsToast
            open={showToast.toastMessage}
            message={showToast.toastMessage}
            nameSpace={showToast.nameSpacing ?? NAMESPACE.COMMON}
            position={DriscollsToastPosition.BOTTOMRIGHT}
            onClose={handleClose}
            severity={showToast.severity ?? DriscollsToastSeverity.SUCCESS}
            autoHideDuration={5000}
        />
    );
};

export default GEPToast;
