import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Companies from './Companies';

const CompaniesRoutes = () => {
    return (
        <Routes>
            <Route path={''} element={<Companies />} />
        </Routes>
    );
};

export default CompaniesRoutes;
