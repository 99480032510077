import { TUIEntitiesAvailable } from '@driscollsinc/users-management-module-ui';
import { IUserSetup } from 'components/appinitializer/v2/IUserSetup';
import { ICountryObject } from 'components/usermanagement/UserManagementTypes';
import React, { useMemo, useState } from 'react';
import ApplicationContextV2 from './ApplicationContextV2';

const AppProviderV2 = (props: any) => {
    const [userSetup, setUserSetup] = useState<IUserSetup>(null);
    const [districtEntities, setDistrictEntities] = useState({} as TUIEntitiesAvailable);
    const [companyEntities, setCompanyEntities] = useState({} as TUIEntitiesAvailable);
    const [countries, setCountries] = useState([] as Array<ICountryObject>);

    const value = useMemo(
        () => ({
            userSetup,
            setUserSetup,
            districtEntities,
            setDistrictEntities,
            companyEntities,
            setCompanyEntities,
            countries,
            setCountries
        }),
        [userSetup, districtEntities, companyEntities, countries]
    );
    return <ApplicationContextV2.Provider value={value}>{props.children}</ApplicationContextV2.Provider>;
};

export default AppProviderV2;
