import { InviteUserEntryPoint, TUIAssignments, TUIPersona, TUISelection, TUISetup } from '@driscollsinc/users-management-module-ui';
import { useOktaAuth } from '@okta/okta-react';
import PageContainer from 'components/v2/PageContainer';
import { ROUTES } from 'constants/routes';
import ApplicationContext from 'context/ApplicationContext';
import usePersonaDetails from 'hooks/v2/usePersonaDetails';
import useUserManagement from 'hooks/v2/useUserManagement';
import useFormatDataStructure from 'hooks/v2/utils/useFormatDataStructure';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useUserManagementService from 'services/v2/useUserManagementService';
import { TInviteUserFormData } from 'types/v2/userManagement';

const UpdateUserPersona = () => {
    const navigate = useNavigate();
    const { oktaAuth } = useOktaAuth();
    const { fetchPersonaDetails, getPersonaDetails } = usePersonaDetails(oktaAuth);
    const { userPersona, userProfile } = useContext(ApplicationContext);
    const [availablePersonas, setAvailablePersonas] = useState([]);
    const [setUp, setSetup] = useState<TUISetup>();
    const [loadingPersonaTypes, setLoadingPersonaTypes] = useState(true);
    const [persona, setPersona] = useState<TUIPersona | null>(null);
    const { initInviteUserSetup } = useUserManagementService();
    const [loadingConfig, setLoadingConfig] = useState(false);
    const [assignments, setAssignments] = useState<TUIAssignments | null>(null);
    const [loadingReports, setLoadingReports] = useState(false);
    const [loadingPersonalInfo, setLoadingPersonalInfo] = useState(true);
    const [loadingAssignments, setLoadingAssignments] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const { userId } = useParams();

    const { getUserDetailsWithPersonas, addPersona } = useUserManagement(oktaAuth);
    const [userPersonas, setUserPersonas] = useState<Array<TUISelection> | []>([]);
    const { addUserProfileMapping, addPersonaReqPayload } = useFormatDataStructure();
    const [assignedPersonaIdsToUser, setAssignedPersonaIdsToUser] = useState<Array<string>>([]);
    const [searchParams] = useSearchParams();
    const [userTypeFromParams, setUserTypeFromParams] = useState('');

    useEffect(() => {
        const personaIds = searchParams.get('personaIds');
        if (personaIds) setAssignedPersonaIdsToUser(personaIds.split(','));
        const userType = searchParams.get('userType');
        if (userType) setUserTypeFromParams(userType);
    }, [searchParams]);

    const fetchUserAndPersona = async () => {
        const personas = await fetchPersonaDetails(String(userPersona));
        const userTypeInternal = userTypeFromParams === 'Internal' ? true : false;
        const personaTypes = personas.filter(
            (persona) => !assignedPersonaIdsToUser.some((id) => id == persona.value) && persona.type.internal === userTypeInternal
        );
        setAvailablePersonas(personaTypes);
        const userProfilePersonaDetails = await getUserDetailsWithPersonas(userId, String(userPersona), userProfile.profile.email);
        const filteredPersonas = addUserProfileMapping(personas, userProfilePersonaDetails);
        setUserPersonas(filteredPersonas);
        setLoadingPersonaTypes(false);
    };

    useEffect(() => {
        assignedPersonaIdsToUser.length > 0 && fetchUserAndPersona();
    }, [userPersona, userId, assignedPersonaIdsToUser]);

    const initialFunction = async () => {
        const { setUp: options } = await initInviteUserSetup(persona.sections);
        setSetup(options);
        setLoadingReports(false);
        setLoadingPersonalInfo(false);
        setLoadingAssignments(false);
    };

    useEffect(() => {
        persona && persona.sections && initialFunction();
    }, [persona]);

    const onSubmit = async (formData: TInviteUserFormData) => {
        setLoadingSubmit(true);
        try {
            const reqPayload = addPersonaReqPayload(formData, persona, userId);
            const addPersonaResponse = await addPersona(reqPayload, userId, formData.persona.value, userProfile.profile.email);
            //console.log('RESULT ', inviteUserResponse);
            // if (inviteUserResponse && inviteUserResponse.ErrorCode === 0) {
            //     setShowToast({ toastMessage: 'User Invited Successfully!' });
            navigate(`${ROUTES.ROOT}${ROUTES.USER_MANAGEMENT}`);
            // } else {
            //     setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Error Occurred!' });
            // }
        } catch (error) {
            // setShowToast({ severity: DriscollsToastSeverity.ERROR, toastMessage: 'Failed to invite user!' });
            console.error('Error occurred while Adding Persona:', error);
        } finally {
            setLoadingSubmit(false);
        }
    };
    return (
        <PageContainer>
            <InviteUserEntryPoint
                isAddPersona
                isLoadingTheAssignments={loadingAssignments}
                assignments={assignments}
                setUp={setUp}
                isLoadingPersonalInfo={loadingPersonalInfo}
                isLoadingReports={loadingReports}
                disablePersonaInfo
                onCancel={() => {
                    navigate(`${ROUTES.ROOT}${ROUTES.USER_MANAGEMENT}`);
                }}
                thePersonaTypes={availablePersonas ?? []}
                thePersona={persona}
                onSubmitHandler={onSubmit}
                defaultPersonaType={availablePersonas}
                isLoadingPersonaTypes={loadingPersonaTypes}
                loadingConfig={loadingConfig}
                loadingSubmit={loadingSubmit}
                onTypeChanged={(sel) => {
                    if (sel) {
                        setPersona(sel);
                        setSetup((setup) => ({ ...setup, assignments }));
                    } else {
                        setLoadingConfig(false);
                        setPersona(null);
                    }
                }}
                personaCount={availablePersonas.length}
            />
        </PageContainer>
    );
};
export default UpdateUserPersona;
