import { ListItemButton, ListItemIcon } from '@mui/material';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import PersonaBaseProtect from 'components/shared/protected/PersonaBaseProtect';
import { NAMESPACE } from 'i18n';
import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import DriscollsToolTip from '../../ui/DriscollsToolTip/DriscollsToolTip';
import ImageComponent from '../shared/ImageComponent/ImageComponent';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';
import { NavigationBarItemProps } from './NavigationBar.types';
import style from './NavigationBarItem.module.scss';

const NavigationBarItem = ({ item, isDesktop }: NavigationBarItemProps): React.ReactElement => {
    const { title, path, iconName, description } = item;
    const getIconPath = (iconName) => `/icons/navigation/${iconName}.svg`;

    return (
        <PersonaBaseProtect personasAllowed={item.personasAllowed} featureName={FEATURES.DASHBOARD} access={'Read'}>
            <div className={style.linkContainer}>
                <ListItemButton
                    component={RouterLink}
                    to={path}
                    sx={{
                        '&.active': {
                            color: isDesktop ? 'text.white' : 'text.primary',
                            bgcolor: isDesktop ? '#3a682b' : 'background.neutral',
                            display: 'flex'
                        }
                    }}
                >
                    <ListItemIcon>
                        {isDesktop ? (
                            <DriscollsToolTip placement="right" headingTitle={title} description={description} className={null} arrow={true}>
                                <ImageComponent src={getIconPath(iconName)} size="small" imageFilter={style.whiteFilter} />
                            </DriscollsToolTip>
                        ) : (
                            <ImageComponent src={getIconPath(iconName)} size={'small'} />
                        )}
                    </ListItemIcon>
                    {!isDesktop && <TypographyTranslated nameSpacing={NAMESPACE.COMMON}>{title}</TypographyTranslated>}
                </ListItemButton>
            </div>
        </PersonaBaseProtect>
    );
};

export default NavigationBarItem;
