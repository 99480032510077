import FeaturesName from "constants/featuresEnum";
import ApplicationContext from "context/ApplicationContext";
import ApplicationContextV2 from "context/ApplicationContextV2";
import React, { PropsWithChildren, useContext } from "react";
import { isFeatureActive } from "utils/features.utils";

interface FeatureProtectProtectProps {
    featureName: FeaturesName
}

/**

 */
const FeatureProtect = (props: PropsWithChildren<FeatureProtectProtectProps>) => {
    const { userSetup } = useContext(ApplicationContextV2);
    const { featureName } = props;
    const { userFeatures } = useContext(ApplicationContext);
    if (window.config.uiConfig.v2UI === 'true' && userSetup) {
        return <>{props.children}</>;
    }
    if (isFeatureActive(userFeatures, featureName)) {
        return <>{props.children}</>;
    } else {
        return null;
    }
};

export default FeatureProtect;