import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes, routesName } from 'routes/routes';
import PBHome from './PBHome';
import PBList from './PBList';
import PBUpdate from './PBUpdate';

const PBRoutes = () => {
    return (
        <Routes>
            <Route path={routes[routesName.PB_HOME].path} element={<PBHome />}>
                <Route path={''} element={<PBList />} />
                <Route path={routes[routesName.PB_FIELDS].path} element={<PBUpdate />} />
            </Route>
        </Routes>
    );
};

export default PBRoutes;
