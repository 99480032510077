import { Call } from '@driscollsinc/driscolls-display-rules';
import { useOktaAuth } from '@okta/okta-react';
import useGetApiTokens from 'hooks/useGetApiTokens';
import { useState } from 'react';
import { GET_PB_FIELDS, UPDATE_PB_FIELDS } from 'services/api/v2/PBUtils';

const usePB = () => {
    const { getTokenAndId } = useGetApiTokens();
    const { oktaAuth } = useOktaAuth();
    const [loading, setLoading] = useState(false);

    const getFields = async (companyId: string): Promise<PBField[]> => {
        setLoading(true);
        try {
            const { token } = await getTokenAndId(oktaAuth);

            const response = await Call(
                {
                    url: GET_PB_FIELDS(companyId),
                    method: 'GET',
                    options: {}
                },
                token
            );

            return response.display.map((res) => ({
                Id: res.Id,
                Name: res.Label,
                Selected: res.IsSelected,
                DataType: res.Datatype,
                Language: res.Language,
                Attribute: res.Attribute
            }));
        } finally {
            setLoading(false);
        }
    };

    const updateField = async (newField: PBField, fieldCount: number) => {
        setLoading(true);
        try {
            const { token } = await getTokenAndId(oktaAuth);
            if (!newField.Id) {
                const response = await Call(
                    {
                        url: UPDATE_PB_FIELDS(),
                        method: 'POST',
                        options: {},
                        data: [
                            {
                                CompanyId: newField.CompanyId,
                                Language: newField.Language,
                                Datatype: newField.DataType,
                                Attribute: newField.Name,
                                IsSelected: newField.Selected ? 1 : 0,
                                Label: 'Attribute' + (fieldCount + 1)
                            }
                        ]
                    },
                    token
                );
                return response.display;
            } else {
                const response = await Call(
                    {
                        url: UPDATE_PB_FIELDS(),
                        method: 'POST',
                        options: {},
                        data: [
                            {
                                Id: newField.Id,
                                CompanyId: newField.CompanyId,
                                Datatype: newField.DataType,
                                Attribute: newField.Attribute,
                                IsSelected: newField.Selected ? 1 : 0,
                                Label: newField.Label
                            }
                        ]
                    },
                    token
                );
                return response.display;
            }
        } finally {
            setLoading(false);
        }
    };

    const deleteField = async (companyId: string, field: PBField) => {
        setLoading(true);
        try {
            const { token } = await getTokenAndId(oktaAuth);

            const response = await Call(
                {
                    url: UPDATE_PB_FIELDS(),
                    method: 'POST',
                    options: {},
                    data: [
                        {
                            Id: field.Id,
                            CompanyId: companyId,
                            Attribute: field.Attribute,
                            IsDeleted: 1
                        }
                    ]
                },
                token
            );
            return response.display;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        getFields,
        updateField,
        deleteField
    };
};

export default usePB;
