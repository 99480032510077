import { ButtonVariant, DriscollsMenu, DriscollsMenuItem, FaIcons, IconSize } from '@driscollsinc/style-guide-web2.0';
import { ROUTES } from 'constants/routes';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';

interface IOnClickMenuEvent {
    value: string;
    syntheticEvent: SyntheticEvent;
}

const PersonaFeaturePopover = () => {
    const { getTextTranslated } = useLang();
    const navigate = useNavigate();
    return (
        <DriscollsMenu buttonVariant={ButtonVariant.TEXT} labelIcon={<FaIcons.FaWhmcs size={IconSize.SMALL} />}>
            <DriscollsMenuItem
                value={ROUTES.PERSONA_MANAGEMENT}
                onClick={(e: IOnClickMenuEvent) => {
                    navigate(e.value);
                }}
            >
                {getTextTranslated('Persona Management', NAMESPACE.COMMON)}
            </DriscollsMenuItem>
            <DriscollsMenuItem
                value={ROUTES.FEATURE_MANAGEMENT}
                onClick={(e: IOnClickMenuEvent) => {
                    navigate(e.value);
                }}
            >
                {getTextTranslated('Feature Management', NAMESPACE.COMMON)}
            </DriscollsMenuItem>
        </DriscollsMenu>
    );
};

export default PersonaFeaturePopover;
