import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import ProtectedRoute from 'components/shared/protected/ProtectedRoute';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Weather from './Weather';

const WeatherRoutes = () => {
    return (
        <Routes>
            <Route
                path=""
                element={
                    <ProtectedRoute>
                        <PersonaBaseProtect personasAllowed={[Persona.SUPER_ADMIN, Persona.GEO_WEATHER]} featureName={FEATURES.WEATHER} access="Read">
                            <Weather />
                        </PersonaBaseProtect>
                    </ProtectedRoute>
                }
            />
        </Routes>
    );
};

export default WeatherRoutes;
