/**
 * Custom Mobile List Renderer
 * @param {*} row
 * @param {*} i
 * @returns ListItemRenderer
 */

import { Attachment, MoreVert } from '@mui/icons-material';
import { Divider, IconButton, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { FEATURES } from 'components/appinitializer/v2/IPersonaFeatures';
import PersonaBaseProtect, { Persona } from 'components/shared/protected/PersonaBaseProtect';
import React, { Fragment, useState } from 'react';
import { isJsonString } from 'utils/helper';
import useGetLocale from '../../hooks/useGetLocale';
import { nonConventionalDateFormatter } from '../../utils/dateFormatterHelper';

const AnnouncementListItemRenderer = ({ row, i, navigate, removeAnnouncement }) => {
    const [openSubMenu, setOpenSubMenu] = useState(null);
    const open = Boolean(openSubMenu);
    const {locale} = useGetLocale();
 
    return (
        <Fragment>
            <ListItem
                alignItems="flex-start"
                secondaryAction={
                    <IconButton edge="end" aria-label="delete" onClick={(e) => setOpenSubMenu(e.currentTarget)}>
                        <MoreVert />
                    </IconButton>
                }
            >
                <ListItemAvatar>{row.AttachementURL && <Attachment />}</ListItemAvatar>
                <ListItemText
                    primary={row.TextHeader}
                    primaryTypographyProps={{
                        fontWeight: 'medium',
                        letterSpacing: 0
                    }}
                    secondary={
                        <Stack spacing={1}>
                            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                {isJsonString(row.TextBody)
                                    ? JSON.parse(row.TextBody)
                                        .blocks.map((itm) => itm.text)
                                        .join(' ')
                                    : row.TextBody}
                            </Typography>
                            <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                {nonConventionalDateFormatter(row.ExpirationDate,locale)}
                            </Typography>
                        </Stack>
                    }
                />
            </ListItem>
            <Divider variant="inset" component="li" />
            <Menu
                id="basic-menu"
                anchorEl={openSubMenu}
                open={open}
                onClose={() => setOpenSubMenu(null)}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
            >
                <MenuItem onClick={() => navigate(`view/${row.AnnouncementId}`)}>View</MenuItem>
                <PersonaBaseProtect personasAllowed={[Persona.SUPER_ADMIN, Persona.REGIONAL_ADMIN]} featureName={FEATURES.ANNOUNCEMENT_DELETE} access='Write'>
                    <MenuItem onClick={() => removeAnnouncement(row.AnnouncementId)}>Delete</MenuItem>
                </PersonaBaseProtect>
            </Menu>
        </Fragment>
    );
};

export default AnnouncementListItemRenderer;
