import { FilePresentOutlined, VideoFile } from '@mui/icons-material';
import { Box, Chip, CircularProgress, Grid } from '@mui/material';
import { NAMESPACE } from 'i18n';
import React from 'react';
import classes from '../../pages/announcement/announcement.module.scss';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';

interface FileURLType {
    url: string;
    fileName: string;
}

const ViewAttachments = (props) => {
    const { fileURL, setVideoDialog, setVideoUrl, isFileLoading, hideHeading } = props;
    return (
        <Grid item container xs={12} alignItems="center" className={classes.fieldsContainer}>
            {!hideHeading && (
                <TypographyTranslated variant="subtitle2" nameSpacing={NAMESPACE.COMMON}>
                    {'Attachments'}
                </TypographyTranslated>
            )}{' '}
            :
            <Box className={classes.attachmentsContainer}>
                {isFileLoading ? (
                    <CircularProgress size="16px" />
                ) : fileURL.length > 0 ? (
                    (fileURL || []).map((file: FileURLType) => {
                        if (file?.url?.includes('mp4'))
                            return (
                                <Chip
                                    key={file.url}
                                    label={file.fileName}
                                    icon={<VideoFile />}
                                    variant="outlined"
                                    onClick={() => {
                                        setVideoDialog(true);
                                        setVideoUrl(file.url);
                                    }}
                                />
                            );
                        else
                            return (
                                <a key={file.url} href={file.url} target="_blank" rel="noopener noreferrer">
                                    <Chip
                                        className={classes.fileChip}
                                        key={file.url}
                                        label={file.fileName}
                                        icon={<FilePresentOutlined />}
                                        variant="outlined"
                                    />
                                </a>
                            );
                    })
                ) : (
                    <TypographyTranslated nameSpacing={NAMESPACE.EVENTS} variant="body2">
                        {'No Attachments Found.'}
                    </TypographyTranslated>
                )}
            </Box>
        </Grid>
    );
};

export default ViewAttachments;
