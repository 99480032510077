import { TUIEntitiesAvailable } from '@driscollsinc/users-management-module-ui';
import { ICountryObject } from 'components/usermanagement/UserManagementTypes';
import { createContext } from 'react';
import { IUserSetup } from '../components/appinitializer/v2/IUserSetup';

interface IAppContextV2 {
    userSetup: IUserSetup | null;
    setUserSetup: (userSetup: IUserSetup) => void;
    districtEntities: TUIEntitiesAvailable;
    setDistrictEntities: (entities: TUIEntitiesAvailable) => void;
    companyEntities: TUIEntitiesAvailable;
    setCompanyEntities: (entities: TUIEntitiesAvailable) => void;
    countries: Array<ICountryObject>;
    setCountries: (countries: Array<ICountryObject>) => void;
}

const ApplicationContextV2 = createContext<IAppContextV2>({} as IAppContextV2);

export default ApplicationContextV2;
