import { MenuItem, Stack } from '@mui/material';
import Link from '@mui/material/Link';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { IRanchObject } from 'components/usermanagement/UserManagementTypes';
import useLang from 'hooks/useLang';
import { NAMESPACE } from 'i18n';
import React, { Fragment } from 'react';
import { logEvent } from '../../../analytics/AnalyticsHandler';
import { EventConstants } from '../../../constants/EventConstants';
import ContextMenuWrapper from './ContextMenuWrapper';
interface IUserManageRanchesProps {
    value: Array<IRanchObject>;
}

const UserManagementRanches = (props: IUserManageRanchesProps) => {
    const cellValue = props.value;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleContextMenu = (action) => {
        if (action === 'Copy') {
            navigator.clipboard.writeText(JSON.stringify(props.value));
        } else if (action === 'CopyRanches') {
            let ranches = props.value.map((item) => {
                return item.RanchNbr;
            });
            navigator.clipboard.writeText(ranches.toString());
        }
        logEvent(EventConstants.GRID_COPY_MASTER, action);
    };

    const open = Boolean(anchorEl);
    const maxItems = 1;
    const { getTextTranslated } = useLang();
    return (
        <ContextMenuWrapper
            handleContextMenu={handleContextMenu}
            menu={[{ label: getTextTranslated('Copy Ranches', NAMESPACE.EVENTS), action: 'CopyRanches' }]}
        >
            {cellValue && cellValue.length > 0 ? (
                <>
                    <Stack direction="row" spacing={0.75}>
                        {cellValue.map((option, index) => (
                            <Fragment key={index}>
                                {index < maxItems ? (
                                    <Link key={option.RanchNbr}>{option.RanchNbr}</Link>
                                ) : index === maxItems ? (
                                    <Fragment>
                                        <Link
                                            component="button"
                                            title={getTextTranslated('Click to view more items', NAMESPACE.EVENTS)}
                                            variant="body2"
                                            onClick={(event) => handleClick(event)}
                                        >
                                            ...More
                                        </Link>
                                        <Popover
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left'
                                            }}
                                            sx={{ height: '25%' }}
                                        >
                                            <Stack spacing={0.75} sx={{ width: 230 }}>
                                                {cellValue.slice(maxItems).map((option) => (
                                                    <MenuItem key={option?.RanchNbr}>
                                                        <Typography title={option.Name} variant="inherit" noWrap>
                                                            {option.RanchNbr + (option.Name ? ' - ' + option.Name : '')}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                            </Stack>
                                        </Popover>
                                    </Fragment>
                                ) : null}
                                {cellValue.length > 1 && index !== cellValue.length - 1 && index < maxItems ? <span>,</span> : ''}
                            </Fragment>
                        ))}
                    </Stack>
                </>
            ) : (
                'N.A'
            )}
        </ContextMenuWrapper>
    );
};

export default UserManagementRanches;
