
import { DriscollsTypographyEnum, DriscollsTypographyStyles, DriscollsTypographyTranslated, DriscollsVContainer } from '@driscollsinc/style-guide-web2.0';
import React from 'react';
import styles from './NonAuthorized.module.scss';

export const NotAuthorized = () => {
    return (
        <DriscollsVContainer className={styles.container} >
            <img src='/Oh_no-bro.svg' />
            <DriscollsTypographyTranslated type={DriscollsTypographyEnum.H6} typographyStyle={DriscollsTypographyStyles.SUBTITLE1}>
                You are not authorized. Please contact your admin
            </DriscollsTypographyTranslated>
        </DriscollsVContainer>
    )

}