import { DriscollsRichText } from '@driscollsinc/style-guide-web2.0';
import { Typography } from '@mui/material';
import { ICalendarEvent } from 'components/dashboard/Calendar/CalendarTypes';
import { NAMESPACE } from 'i18n';
import React from 'react';
import useGetLocale from '../../hooks/useGetLocale';
import { simpleDateFormatterHandler } from '../../utils/dateFormatterHelper';
import { TypographyTranslated } from '../shared/Typography/TypographyTranslated';
import style from './EventDetails.module.scss';

const EventDetails = ({ event }: { event: ICalendarEvent | null }): JSX.Element => {
    console.log('AT: EVENT DETAILS ===>', event);

    const { locale } = useGetLocale();

    if (!event) return <p>Sorry we can not find the event</p>;
    return (
        <>
            <div className={style.dateInfo}>
                <span>
                    <TypographyTranslated variant="overline" nameSpacing={NAMESPACE.EVENTS}>
                        Start Date
                    </TypographyTranslated>
                    <Typography variant="subtitle1">{simpleDateFormatterHandler(event.StartDate, locale)}</Typography>
                </span>
                <span>
                    <TypographyTranslated variant="overline" nameSpacing={NAMESPACE.EVENTS}>
                        End Date
                    </TypographyTranslated>
                    <Typography variant="subtitle1">{simpleDateFormatterHandler(event.EndDate, locale)}</Typography>
                </span>
                <span>
                    <TypographyTranslated variant="overline" nameSpacing={NAMESPACE.EVENTS}>
                        Target Districts
                    </TypographyTranslated>
                    <Typography variant="subtitle1">{event.DistrictCode}</Typography>
                </span>
            </div>
            <DriscollsRichText defaultValue={event.Description} readOnly={true} />
        </>
    );
};

export default EventDetails;
