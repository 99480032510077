import { FeaturesPage } from '@driscollsinc/users-management-module-ui';
import React from 'react';
import styles from './PersonaManagement.module.scss';

const FeaturePersona = () => {
    return (
        <div className={styles.personaModuleContainer}>
            <FeaturesPage
                featuresList={[
                    {
                        Name: 'dashboard',
                        HomePage: true,
                        read: true,
                        write: true
                    },
                    { Name: 'userManagement' },
                    { Name: 'reports' },
                    { Name: 'announcement' },
                    { Name: 'support' },
                    { Name: 'ptiApp' }
                ]}
                onSubmitHandler={() => console.log('context values => ')}
            />
        </div>
    );
};

export default FeaturePersona;
