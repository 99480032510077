import { useContext, useEffect, useState } from 'react';
import ApplicationContext from '../context/ApplicationContext';

const useGetAllRanches = () => {
    const { ranches: companies } = useContext(ApplicationContext);
    const [ranches, setRanches] = useState([]);

    useEffect(() => {
        let ranchesList = [];
        if (companies) {
            ([...companies] || []).forEach((ranch) => ranch.Ranches && ranch?.Ranches?.length > 0 && ranchesList.push(...ranch.Ranches));
            ranchesList = (ranchesList || []).map((item) => ({
                ...item,
                label: (item.RanchNbr || item.Code) + ' - ' + (item.Name ?? item.RanchName),
                value: item.RanchNbr || item.Code
            }));
        }
        setRanches(ranchesList);
    }, [companies]);

    return ranches;
};

export default useGetAllRanches;
