import { i18nBerryConnect } from '@driscollsinc/berry-connect-module-ui';
import { i18nGa } from '@driscollsinc/ga-module-ui';
import { i18nPti } from '@driscollsinc/pti-module-ui';
import { i18next, Language, reacti18next } from '@driscollsinc/style-guide-web2.0';
import { i18nUsersModuleInstance } from '@driscollsinc/users-management-module-ui';
import { i18nWeather } from '@driscollsinc/weather-module-ui';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { defaultLanguageHandler } from './utils/languageHelper';

export enum NAMESPACE {
    MAIN = 'gepMain',
    COMMON = 'common',
    ANNOUNCEMENT = 'gepAnnouncement',
    DASHBOARD = 'gepDashboard',
    SUPPORT = 'gepSupport',
    REPORT = 'gepReport',
    USERS = 'gepUserManagement',
    EVENTS = 'gepEvents',
    WEATHER = 'gepWeather',
    PTI = 'gepPti',
    ANALYTICS = 'gepGa',
    BERRYCONNECT = 'gepBerryConnect',
    PB = 'gepPb',
    COMPANIES = 'gepCompanies'
}

const supportedLngs = [Language.ENGLISH, Language.SPANISH, Language.FRENCH];
export const defaultLanguage = defaultLanguageHandler();
export const allNameSpaces = [
    NAMESPACE.COMMON,
    NAMESPACE.ANNOUNCEMENT,
    NAMESPACE.DASHBOARD,
    NAMESPACE.SUPPORT,
    NAMESPACE.REPORT,
    NAMESPACE.EVENTS,
    NAMESPACE.WEATHER,
    NAMESPACE.PTI,
    NAMESPACE.USERS
];

const initLangData = {
    lng: Language.ENGLISH,
    backend: {
        loadPath: `${window.config.ASSETS_URL}/translations/{{lng}}/{{ns}}.json`,
        crossDomain: true
    }
};

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(reacti18next.initReactI18next)
    .init({
        fallbackLng: false,
        defaultNS: NAMESPACE.COMMON,
        supportedLngs,
        returnObjects: true,
        interpolation: { escapeValue: false },
        react: { useSuspense: false },
        ns: allNameSpaces,
        ...initLangData
    });

// user management module instance initializing
i18nUsersModuleInstance
    .use(Backend)
    .use(LanguageDetector)
    .use(reacti18next.initReactI18next)
    .init({
        ...initLangData,
        ns: [NAMESPACE.COMMON, NAMESPACE.USERS]
    });

// pti module instance initializing
i18nPti
    .use(Backend)
    .use(LanguageDetector)
    .use(reacti18next.initReactI18next)
    .init({
        ...initLangData,
        ns: [NAMESPACE.PTI]
    });

// berry connect module instance initializing
i18nBerryConnect
    .use(Backend)
    .use(LanguageDetector)
    .use(reacti18next.initReactI18next)
    .init({
        ...initLangData,
        ns: [NAMESPACE.BERRYCONNECT]
    });

// weather module instance initializing
i18nWeather
    .use(Backend)
    .use(LanguageDetector)
    .use(reacti18next.initReactI18next)
    .init({
        ...initLangData,
        ns: [NAMESPACE.WEATHER]
    });

i18nGa
    .use(Backend)
    .use(LanguageDetector)
    .use(reacti18next.initReactI18next)
    .init({
        ...initLangData,
        ns: [NAMESPACE.ANALYTICS]
    });

export default i18next;
