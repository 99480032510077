import { urls } from 'constants/urls';
import APIEndPoints from 'services/api';

export const GET_REPORTS_LIST = (lang: string) => {
    return `${APIEndPoints.BASE()}${urls.REPORTS}/list?lang=${lang}`;
};

export const GET_ASSIGNED_REPORTS = (userOktaId, lang = 'en') => {
    return `${APIEndPoints.BASE()}${urls.USERS}/${urls.REPORTS}/${userOktaId}?lang=${lang}`;
};
export const GET_REPORTS_BY_CATEGORY = () => {
    return `${APIEndPoints.BASE()}${urls.REPORT}/${urls.CATEGORY}`;
};
